<template>
  <div>
    <v-card outlined>
      <v-card-title> Configurações Ronda </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-checkbox
              label="Ativar função Ronda"
              v-model="patrolConfig.enabled"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              icon
              @click="addRuleDialog = true"
              :disabled="!patrolConfig.enabled"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="patrolConfig.rules">
          <v-col>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th class="text-left">Objeto alvo</th>
                    <th class="text-left">Confiança</th>
                    <th class="text-left">Tempo para verificar / Tolerancia</th>
                    <th class="text-left">Quantidade esperada</th>
                    <th class="text-left">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in patrolConfig.rules" :key="index">
                    <td>{{ item.class }}</td>
                    <td>{{ item.confidence }} %</td>
                    <td>
                      {{ item.timeoutToCheck }} Minutos /
                      {{ item.timeoutTolerance }} Minutos
                    </td>
                    <td>
                      Minimo: {{ item.countMin }} - Maximo:
                      {{ item.countMax }}
                    </td>
                    <td>
                      <v-btn icon @click="edtRule(index)">
                        <v-icon color="warning">mdi-pen</v-icon>
                      </v-btn>
                      <v-btn icon @click="patrolConfig.rules.splice(index, 1)">
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- dialog para adicionar regra -->
    <v-dialog v-model="addRuleDialog" width="500" dark>
      <v-card>
        <v-card-title class="text-h5">
          {{ modelRulePatrol.index > -1 ? "Editar" : "Adicionar" }} Regra
        </v-card-title>
        <v-card-text>
          <v-form ref="formPatrol">
            <v-autocomplete
              :items="coconames"
              label="Objeto alvo"
              v-model="modelRulePatrol.class"
              :rules="val.requiredField"
              outlined
            ></v-autocomplete>
            <v-text-field
              label="Confiança mínima para detecção"
              v-model.number="modelRulePatrol.confidence"
              outlined
              suffix="%"
              step="10"
              min="10"
              max="90"
              :rules="val.reqFieldNumberMinMax(10, 90)"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Tempo de espera para a checagem"
              v-model.number="modelRulePatrol.timeoutToCheck"
              outlined
              suffix="Minutos"
              step="10"
              min="30"
              max="600"
              :rules="val.reqFieldNumberMinMax(30, 600)"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Tolerancia de tempo para a checagem"
              v-model.number="modelRulePatrol.timeoutTolerance"
              outlined
              suffix="Minutos"
              :rules="val.reqFieldNumberMinMax(0, 30)"
              min="0"
              max="30"
              type="number"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-text-field
                  label="Quantidade de objetos minimo"
                  v-model.number="modelRulePatrol.countMin"
                  outlined
                  min="1"
                  max="10"
                  :rules="val.reqFieldNumberMinMax(1, 10)"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Quantidade de objetos maximo"
                  v-model.number="modelRulePatrol.countMax"
                  outlined
                  min="1"
                  max="10"
                  :rules="val.reqFieldNumberMinMax(1, 10)"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="cancel"> Cancelar </v-btn>
          <v-btn color="success" text @click="addRule(modelRulePatrol.index)">
            {{ modelRulePatrol.index > -1 ? "Editar" : "Adicionar" }} Regra
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Validator from "@/Utils/InputValidation";
export default {
  name: "ConfigCarps",
  props: {
    patrolConfig: {
      type: Object,
      required: true,
    },
    coconames: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addRuleDialog: false,
      modelRulePatrol: {
        class: "",
        confidence: 30,
        timeoutToCheck: 30,
        timeoutTolerance: 10,
        countMax: 1,
        countMin: 1,
      },
      val: Validator,
    };
  },
  methods: {
    addRule(index) {
      if (!this.$refs.formPatrol.validate()) {
        return;
      }
      // se existir o index, é uma edição
      if (typeof index == "number") {
        // deletar o index do model
        delete this.modelRulePatrol.index;
        // substituir o item no array
        this.patrolConfig.rules[index] = this.modelRulePatrol;
      } else {
        // se não existir o array de regras, cria um
        if (!Array.isArray(this.patrolConfig.rules)) {
          this.patrolConfig.rules = [];
        }
        // adicionar o item no array
        this.patrolConfig.rules.push(this.modelRulePatrol);
      }
      this.cancel();
    },
    edtRule(index) {
      // setar o model com o item do array
      this.modelRulePatrol = this.patrolConfig.rules[index];
      // setar o index para edição
      this.modelRulePatrol.index = index;
      this.addRuleDialog = true;
    },
    // resetar o model
    cancel() {
      this.modelRulePatrol = {
        class: "",
        confidence: 30,
        timeoutToCheck: 30,
        timeoutTolerance: 10,
        countMax: 1,
        countMin: 1,
      };
      this.addRuleDialog = false;
      this.$refs.formPatrol.resetValidation();
    },
  },
};
</script>

<style>
</style>