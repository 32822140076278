<template>
  <div>
    <v-card outlined>
      <v-card-title>
        Região / Zona <v-spacer></v-spacer>
        <v-btn icon @click="addRegZone = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        Manupilação das Regiões e Zonas de detecção de objetos, para cada região
        é possível definir uma zona de detecção de objetos. <br />
        Caso não tenha uma zona definida, será utilizada a zona que está na
        configuração da câmera <br />
        A região é encontrada na tela de deteção de objetos onde ao lado do NOME
        de cada REGRA tem o NÚMERO
      </v-card-subtitle>
      <v-card-text>
        <v-row v-if="regionZones.length > 0">
          <v-col>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <!-- class="text-left" -->
                    <th>Região</th>
                    <th>Zona</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in regionZones" :key="index">
                    <td>{{ item.region }}</td>
                    <td>{{ item.zone }}</td>
                    <td>
                      <v-btn icon @click="edtRule(index)">
                        <v-icon color="warning">mdi-pen</v-icon>
                      </v-btn>
                      <v-btn icon @click="regionZones.splice(index, 1)">
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- dialog para adicionar regra -->
    <v-dialog v-model="addRegZone" width="500" dark>
      <v-card>
        <v-card-title class="text-h5">
          {{ modelRegzon.index > -1 ? "Editar" : "Adicionar" }} mapeamento de
          região
        </v-card-title>
        <v-card-text>
          <v-form ref="formRegZon">
            <v-row>
              <v-col>
                <v-text-field
                  label="Número da Região"
                  v-model.number="modelRegzon.region"
                  outlined
                  min="0"
                  :rules="val.requiredField"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Número da zona"
                  v-model.number="modelRegzon.zone"
                  outlined
                  min="0"
                  max="999"
                  :rules="val.reqFieldNumberMinMax(1, 999)"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="addRegZone = false">
            Cancelar
          </v-btn>
          <v-btn color="success" text @click="addRule(modelRegzon.index)">
            {{ modelRegzon.index > -1 ? "Editar" : "Adicionar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Validator from "@/Utils/InputValidation";
export default {
  name: "ConfigCarps",
  props: {
    regionZones: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addRegZone: false,
      regzon: {
        region: 0,
        zone: 0,
      },
      modelRegzon: {
        region: 0,
        zone: 0,
      },
      val: Validator,
    };
  },
  methods: {
    addRule(index) {
      if (!this.$refs.formRegZon.validate()) {
        return;
      }
      // se existir o index, é uma edição
      if (typeof index == "number") {
        // deletar o index do model
        delete this.modelRegzon.index;
        // substituir o item no array
        this.regionZones[index] = { ...this.modelRegzon };
      } else {
        // se não existir o array de regras, cria um
        if (!Array.isArray(this.regionZones)) {
          this.regionZones = [];
        }
        // adicionar o item no array
        this.regionZones.push({ ...this.modelRegzon });
      }
      this.cancel();
    },
    edtRule(index) {
      // setar o model com o item do array
      this.modelRegzon = this.regionZones[index];
      // setar o index para edição
      this.modelRegzon.index = index;
      this.addRegZone = true;
    },
    // resetar o model
    cancel() {
      this.modelRegzon = {
        region: 0,
        zone: 0,
      };
      this.addRegZone = false;
      this.$refs.formRegZon.resetValidation();
    },
  },
};
</script>

<style>
</style>