<template>
  <div v-show="models.includes(camera.modelId)">
    <v-row>
      <v-col cols="2">
        <v-autocomplete
          :items="['camera', 'dvr']"
          label="Tipo de equipamento"
          v-model="camera.config.device"
        ></v-autocomplete>
      </v-col>
      <v-col>
        
        <v-autocomplete
          :items="profiles"
          label="Profiles da Camera"
          hint="Clique no icone para atualizar,  caso seja um DVR, selecione o perfil da camera"
          v-model="camera.config.profile"
          prepend-inner-icon="mdi-refresh"
          @click:prepend-inner="getProfiles"
          no-data-text="Clique no icone para atualizar"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
export default {
  name: "profiles",
  props: ["camera"],
  data: () => ({
    models: [8],
    reqField: requiredField,
    profiles: [],
  }),
  methods: {
    getProfiles() {
      this.$emit("validateForm");
      this.profiles = [];
      if (this.camera.url && this.camera.user && this.camera.password) {
        this.$http.post("onvif/profiles", this.camera).then((response) => {
          if(Array.isArray(response.data)){
            response.data.forEach((element) => {
              this.profiles.push({
                text: `${element.id} - ${element.name} (${element.token})`,
                value: element.id,
              });
            });
          }
        });
      }
    },
  },
};
</script>

<style>
</style>