const turf = require('@turf/turf');

// função que faz as validações das detecções
exports.verifyAlarm = (area = {}, point = {}) => {
  let inside = false;
  // se o tipo estiver na exceção sai da verificação
  // if (exceptions.findIndex((e) => e == point.c) > -1) return false;
  // verifico se é para validar dentro do poligono, se não vier a informação faço como padrão
  let insidePoly = typeof area.insidePoly == 'boolean' ? area.insidePoly : true;
  // verifico se é para validar interseção do poligono, se não vier a informação faço como padrão
  let interceptPoly = typeof area.interceptPoly == 'boolean' ? area.interceptPoly : true;
  // se não ouver desenho e não ouver tipo saio
  if (area.coords == 0 || !area.type) return false;
  // se for um poligono e for necessario verificar dentro
  if (area.type == 2 && insidePoly) {
    // compara pelo centro da caixa
    inside = ray_casting([point.ct.x, point.ct.y], area.coords);
  }
  // se for uma linha ou um poligono com interceção
  if (area.type == 1 || interceptPoly) {
    let line1 = turf.lineString(area.coords);
    let poly = turf.lineString(convertBBToPoints(point));
    inside = (turf.booleanIntersects(line1, poly) || inside);
  }
  return inside;
}

// verifica se esta dentro do poligono
function ray_casting(point, polygon) {
  let n = polygon.length,
    is_in = false,
    x = point[0],
    y = point[1],
    x1, x2, y1, y2;

  for (let i = 0; i < n - 1; ++i) {
    x1 = polygon[i][0];
    x2 = polygon[i + 1][0];
    y1 = polygon[i][1];
    y2 = polygon[i + 1][1];

    if (y < y1 != y < y2 && x < (x2 - x1) * (y - y1) / (y2 - y1) + x1) {
      is_in = !is_in;
    }
  }
  return is_in;
}


exports.getProportionalSize = (imageX, imageY, areas = []) => {
  let x = imageX / 512;
  let y = imageY / 512;
  // copiando os dados da area para outro array e manipulando ele
  let a = JSON.parse(JSON.stringify(areas));
  a.forEach(points => {
    points.coords.forEach((p) => {
      p[0] = parseInt(p[0] * x);
      p[1] = parseInt(p[1] * y);
    });
  });
  return a;
}

exports.getProportionalSizeArray = (imageX, imageY, areas = []) => {
  let x = imageX / 512;
  let y = imageY / 512;
  // copiando os dados da area para outro array e manipulando ele
  let a = JSON.parse(JSON.stringify(areas));
  a.forEach(p => {
    p[0] = parseInt(p[0] * x);
    p[1] = parseInt(p[1] * y);
  });
  return a;
}

// converte um BBOX para um poligono para conseguir validar a interceção com a linha
function convertBBToPoints(posicao) {
  let points = [];
  points.push([posicao.x, posicao.y]);
  points.push([posicao.x + posicao.w, posicao.y]);
  points.push([posicao.x + posicao.w, posicao.y + posicao.h]);
  points.push([posicao.x, posicao.y + posicao.h]);
  points.push([posicao.x, posicao.y]);
  return points;
}