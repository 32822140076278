<template>
  <div v-show="models.includes(camera.modelId)">
    <v-row>
      <v-col>
        <v-text-field
          :label="getLabel(camera.modelId)"
          v-model="camera.url"
          :rules="reqField"
        ></v-text-field>
      </v-col>
      <v-col cols="2" v-show="camera.modelId == 1 || camera.modelId == 2">
        <v-autocomplete
          :items="this.$store.getters.getProxysSelect"
          label="Proxy"
          v-model="camera.proxyId"
          hint="Selecione um proxy se necessario"
          persistent-hint
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto" class="mt-2">
        <ToolTipIcon
          v-if="!$store.getters.getTestPortRunning"
          text="Testar porta da camera"
          icon="mdi-ethernet"
          top="true"
          @click="testPort"
        />
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Usuário"
          v-model="camera.user"
          :rules="reqField"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Senha"
          v-model="camera.password"
          :rules="reqField"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Numero da Zona"
          v-model.number="camera.zone"
          :rules="reqField"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import ToolTipIcon from "@/components/ToolTipIcon.vue";
export default {
  name: "Type1AND2",
  props: ["camera"],
  components: {
    ToolTipIcon,
  },
  data: () => ({
    models: [1, 2, 3, 4, 7, 8, 9, 10],
    reqField: requiredField,
  }),
  methods: {
    getLabel(modelId) {
      switch (modelId) {
        case 1:
        case 2:
        case 3:
        case 10:
          return "Endereço Http com Porta";
        case 4:
          return "Endereço RTSP com porta";
        case 7:
          return "Endereço do Dguard completo ou use o importador";
        case 9:
          return "Endereço do Digifort com o nome da camera ou use o importador";
        default:
          break;
      }
    },
    testPort() {
      let url = new URL(this.camera.url);
      this.$store.dispatch("testPort", {
        address: url.hostname,
        port: url.port || 80,
      });
    },
  },
};
</script>

<style>
</style>