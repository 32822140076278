<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Ver Camera</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-black">Indice:</td>
                  <td>{{ item.id }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Nome:</td>
                  <td>{{ item.name }}</td>
                </tr>

                <tr>
                  <td class="font-weight-black">Cliente:</td>
                  <td>
                    {{ $store.getters.getClienteNameById(item.clientId) }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-black">Modelo:</td>
                  <td>
                    <!-- {{ item.modelId }} -->
                    {{ $store.getters.getCameraTypeById(item.modelId) }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-black">Grupo:</td>
                  <td>{{ $store.getters.getGroupNameById(item.groupId) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Url:</td>
                  <td>
                    <v-row align="center">
                      <v-col>
                        <span>{{ item.url }}</span>
                      </v-col>
                      <v-col cols="auto">
                        <ToolTipIcon
                          v-if="!$store.getters.getTestPortRunning"
                          text="Testar porta da camera"
                          icon="mdi-ethernet"
                          top="true"
                          @click="testPort"
                        />
                        <v-progress-circular
                          v-else
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-black">Zona:</td>
                  <td>{{ item.zone }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Usuário:</td>
                  <td>{{ item.user }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Senha:</td>
                  <td>{{ item.password }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Hash:</td>
                  <td>{{ item.hash }}</td>
                </tr>
                <tr v-show="item.modelId == 9">
                  <td class="font-weight-black">WebHook motion url:</td>
                  <td>
                    <v-row align="center">
                      <v-col>
                         {{ getWebHookDigifortUrl(item.hash) }}
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          @click="copyUrl(getWebHookDigifortUrl(item.hash))"
                        >
                          Copiar URL
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>

                <tr>
                  <td class="font-weight-black">Cadastrado em:</td>
                  <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Editado em:</td>
                  <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Desativada:</td>
                  <td>
                    {{ item.deletedAt ? "SIM" : "NÃO" }} em:
                    {{ new Date(item.deletedAt).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-black">Enviando eventos:</td>
                  <td>{{ item.sendEvent ? "SIM" : "NÃO" }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Configurações Avançadas:</td>
                  <td>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(cfg, cidx) in explodeConfig(item.config)"
                            :key="cidx"
                          >
                            <td>{{ cfg.key }}</td>
                            <td>{{ cfg.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card outlined>
            <v-card-title>
              <span class="headline">Observações</span>
            </v-card-title>
            <v-card-text>
              <div v-html="getDescription(item.modelId)"></div>
            </v-card-text>
          </v-card>
        </div>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ToolTipIcon from "@/components/ToolTipIcon.vue";
export default {
  name: "VerMonitoramento",
  components: { ToolTipIcon },
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    copyUrl(url) {
      navigator.clipboard.writeText(url);
      this.$store.dispatch("resetSnack");
      this.$store.dispatch(
        "showSuccessSnack",
        `URL do WebHook copiada para area de transferencia`
      );
    },
    explodeConfig(config) {
      let configArray = [];
      for (let key in config) {
        configArray.push({ key: key, value: config[key] });
      }
      return configArray;
    },
    testPort() {
      let url = new URL(this.item.url);
      this.$store.dispatch("testPort", {
        address: url.hostname,
        port: url.port || 80,
      });
    },
    getDescription(value) {
      let camera = this.$store.getters.getCamerasTypes.find(
        (e) => e.value == value
      );
      if (camera) {
        return camera.description;
      } else {
        return "Nenhuma descrição encontrada";
      }
    },
    getWebHookDigifortUrl(hash) {
      return `${window.location.origin}/api/digifort/motion?camera=${hash}`;
    },
  },
};
</script>

<style>
</style>