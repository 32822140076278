<template>
  <v-dialog v-bind:value="value" persistent max-width="1000">
    <v-card class="grey darken-3">
      <v-card-title>
        <!-- titulo -->
        <span>Cadastrar Ação</span>
        <v-spacer></v-spacer>
        <!-- botão fechar -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-1">
          <!-- coluna do ativo -->
          <v-col cols="auto">
            <v-checkbox label="Ativa" v-model="action.enabled"></v-checkbox>
          </v-col>
          <!-- coluna do icone -->
          <v-col>
            <v-autocomplete
              v-model="action.icon"
              label="Icone"
              hint="Selecione um Icone"
              :items="icons"
              persistent-hint
              outlined
              prepend-inner-icon="mdi-format-color-fill"
            >
              <template v-slot:append-item>
                <v-slide-x-reverse-transition mode="out-in">
                </v-slide-x-reverse-transition>
              </template>
              <template v-slot:item="{ item }">
                <v-icon class="mx-2"> {{ item }}</v-icon>
                {{ item }}
              </template>
            </v-autocomplete>
          </v-col>
          <!-- coluna do nome -->
          <v-col>
            <v-text-field
              outlined
              v-model="action.name"
              label="Nome da ação"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- linha das tabelas -->
        <v-row>
          <!-- tabela de quandofazer -->
          <v-col>
            <TableDo
              :toDo="action.do"
              @dialogDo="dialogDo = true"
              @remove="action.do.splice($event, 1)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mx-2" text @click="save"> Salvar </v-btn>
        <v-btn color="warning" class="mx-2" text @click="close">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--  dialog oque fazer -->
    <DialogDo :devices="getDevices" v-model="dialogDo" @save="addDo" />
  </v-dialog>
</template>

<script>
import DialogDo from "@/views/Automacao/components/DialogDo.vue";
import TableDo from "@/views/Automacao/components/TableDo.vue";
import { mapGetters } from "vuex";
export default {
  name: "DialogFromaction",
  components: {
    DialogDo,
    TableDo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    act: {
      type: Object,
    },
  },
  data: () => ({
    dialogWhen: false,
    dialogDo: false,
    icons: [],
    devices: [],
    action: {
      enabled: true,
      name: "",
      icon: "",
      do: [],
    },
  }),
  computed: {
    ...mapGetters(["getDevices"]),
  },
  methods: {
    addDo(data) {
      console.log("addDo", data, this.action.do);
      this.action.do.push(data);
    },
    close() {
      this.action = {
        enabled: true,
        name: "",
        icon: "",
        do: [],
      };
      this.$emit("input", false);
      this.$emit("cancel");
    },
    save() {
      if (this.action.name == "") {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "Nome da ação é obrigatório");
        return;
      }
      if (this.action.icon == "") {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "Icone da ação é obrigatório");
        return;
      }
      if (this.action.do.length == 0) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showErrorSnack",
          "É necessário ter pelo menos uma ação"
        );
        return;
      }
      this.$emit("save", this.action);
      this.close();
    },
    transformDelay2Millis(delay) {
      let total = delay.hours * 3600 + delay.minutes * 60 + delay.seconds;
      return total * 1000;
    },
    getIcones() {
      this.$http("icone").then((resp) => {
        let ic = [];
        resp.data.forEach((element) => {
          ic.push(element.name);
        });
        this.icons = ic;
      });
    },
  },
  watch: {
    value: function (value) {
      if (value) {
        this.$store.dispatch("loadDevices");
        if (this.icons.length == 0) {
          this.getIcones();
        }
        if (this.act.id) {
          this.action = this.act;
        }
      }
    },
  },
};
</script>

<style>
.height-100 {
  height: 100%;
}
</style>