<template>
  <div>
    <v-dialog v-model="value" persistent max-width="400px">
      <v-card>
        <v-card-title> Iniciar Manual - {{ camera.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="8">
              <v-text-field
                  outlined
                  label="Hora de Parar"
                  v-model="payload.endTime"
                  type="time"
                ></v-text-field>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
           </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <v-btn color="warning darken-1" text @click="start"> Iniciar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogStartForce",
  props: ["value", "camera"],
  data: () => ({
    eventsSelected: [],
    eventsAvaliable: [],
    coconames: [],
    names: [],
    payload: {
      cameraId: null,
      endTime: "",
    },
  }),
  methods: {
    close() {
      this.$emit("input", false);
    },
    start() {
      this.$http.post("camera/start", this.payload).then((resp) => {
        if (resp.status < 399) {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Câmera iniciada com Sucesso");
          this.close();
        }
      });
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        this.payload.cameraId = this.camera.id;
        let date = new Date();
        date.setMinutes(date.getMinutes() + 1);
        this.payload.endTime = date.toTimeString().slice(0, 8);
      }
    },
  },
};
</script>

<style scoped>
</style>