<template>
  <div>
    <v-dialog v-model="value" persistent scrollable heigth="80vh">
      <v-card class="grey darken-3 meuDialog">
        <v-card-title>
          <!-- titulo -->
          <span>Snapshot {{ camera.name }}</span>
          <v-spacer></v-spacer>
          <!-- botão atualizar -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="getImage">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Atualizar Imagem</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-select :items="times" label="Selecione o tempo para atualizar a imagem" v-model="autoUpdate"
            @change="updateTime">
          </v-select>
          <v-spacer></v-spacer>
          <!-- botão fechar -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Fechar Janela</span>
          </v-tooltip>
        </v-card-title>
        <!-- subtitlo para mostrar a resolução -->
        <v-card-subtitle>
          <small v-show="resolucao.width">
            Resolução da Imagem: {{ this.resolucao.width }}x{{
              this.resolucao.height
            }}
          </small>
        </v-card-subtitle>
        <v-card-text>
          <!-- linha do canvas -->
          <v-row align="center" justify="center">
            <!-- Mostra a imagem quando carregada -->
            <!-- <v-img v-show="!loadingImage" :src="img" width="100%" contain>
            </v-img> -->
            <img :src="img" width="80%" v-show="!loadingImage" />
            <!-- div com o progress  -->
            <div v-show="loadingImage" class="minhaTabela ma-8 pa-8" style="
                width: 300px;
                height: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
              ">
              <v-progress-circular :size="300" color="primary" indeterminate>
                Carregando snapshot
              </v-progress-circular>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogSnapshot",
  props: ["value", "cameraId", "cameraName", "camera"],
  data: () => ({
    img: null,
    loadingImage: false,
    resolucao: { width: 0, height: 0 },
    sizes: {},
    timer: null,
    autoUpdate: 0,
    times: [{ value: 0, text: "Parar" }, { value: 1000, text: "1 Segundo" }, { value: 2000, text: "2 Segundos" }, { value: 5000, text: "5 Segundos" }, { value: 10000, text: "10 Segundos" }, { value: 30000, text: "30 Segundos" }, { value: 60000, text: "1 Minuto" }],
  }),
  methods: {
    close() {
      this.points = [];
      this.type = "";
      this.$emit("input", false);
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
    getImage() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.autoUpdate) {
        this.loadingImage = true;
      }
      // busca a imagem do servidor
      this.resolucao.width = 0;
      this.$http
        .post("/snapshot", { id: this.cameraId }, {
          responseType: "arraybuffer",
        })
        .then((img) => {
          let image = new Image();
          image.onload = () => {
            this.resolucao.width = image.width;
            this.resolucao.height = image.height;
          };
          this.img =
            "data:" +
            img.headers["content-type"] +
            ";base64," +
            Buffer.from(img.data).toString("base64");
          image.src = this.img;
          this.loadingImage = false;
          if (!this.autoUpdate) {
            return this.img;
          }
          this.timer = setTimeout(() => {
            this.getImage();
          }, this.autoUpdate);
          return this.img;
        })
        .catch(() => {
          this.close();
          // console.log(err);
          this.loadingImage = false;
        });
    },
    updateTime() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.autoUpdate) {
        return;
      }
      this.timer = setTimeout(() => {
        this.getImage();
      }, this.autoUpdate);
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.getImage();
      } else {
        this.img = "";
      }
    },
  },
  mounted() { },
};
</script>

<style scoped>
#desenho {
  cursor: crosshair;
}

canvas {
  border: 2px solid;
  border-collapse: collapse;
  width: "100%";
}

.meuDialog {
  min-height: "800px" !important;
}
</style>