<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title> Importar câmeras do Dguard </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <v-card-text>
            <v-form ref="formDguard">
              <v-row v-for="server in getCamerasDguard" :key="server.guid">
                <v-col>
                  <!-- toobar com o nome do serividor -->
                  <v-toolbar dark color="success" dense>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>
                      {{ server.name }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <!-- tabela das cameras -->
                  <v-simple-table dark dense v-if="server.cameras.length">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Id</th>
                          <th class="text-left">Nome</th>
                          <th class="text-left">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="cam in server.cameras" :key="cam.id">
                          <td>{{ cam.id }}</td>
                          <td>{{ cam.name }}</td>
                          <td>
                            <ToolTip
                              icon="mdi-image-search"
                              top="true"
                              text="Ver snapshot"
                              @click="getSnapshot(server, cam)"
                            />
                            <ToolTip
                              icon="mdi-image-move"
                              top="true"
                              text="Importar Câmera"
                              @click="useCamera(server, cam)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!-- Mostra sem cameras -->
                  <div class="d-flex justify-center my-1" v-else>
                    <h1 class="mt-1">Sem cameras neste servidor</h1>
                  </div>
                  <!-- <div>
                    {{server}}
                  </div> -->
                </v-col>
              </v-row>
            </v-form>
            <v-img
              v-show="$store.getters.getSnapImport"
              :src="$store.getters.getSnapImport"
            ></v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="getCameras" color="success">Atualizar cameras</v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  requiredField,
  reqFieldMinLength,
} from "../../../Utils/InputValidation";
import ToolTip from "../../../components/ToolTipIcon.vue";
export default {
  name: "ImportFromDguard",
  components: { ToolTip },
  props: {
    item: {
      type: Object,
      required: false,
    },
    client: {
      type: Object,
      required: true,
    },
    monit: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    camera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogIP: false,
      requiredField,
      reqFieldMinLength,
    };
  },
  methods: {
    save() {
      this.$emit("save", this.item);
    },
    close() {
      this.$emit("input", false);
    },
    getSnapshot(server, camera) {
      let payload = {
        server: server.guid,
        camera: camera.id,
        clientId: this.client.id,
      };
      this.$store.dispatch("getSnapFromDguard", payload);
    },
    useCamera(server, camera) {
      let payload = {
        server: server.guid,
        camera: camera.id,
        clientId: this.client.id,
        cb: (data)=>{
          this.$emit("import", {...data, server, camera});
          this.close();
        }
      };
      this.$store.dispatch("getCameraUrlDguard", payload);
    },
    getCameras() {
      this.$store.dispatch("loadCamerasFromDguard", this.client.id);
    },
  },
  computed: {
    getCamerasDguard: {
      get() {
        return this.$store.getters.getCamerasDguard;
      },
      set() {},
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getCameras();
        this.$store.dispatch("setSnapImport", null);
        this.$store.dispatch("setDguardCameras", []);
      }
    },
  },
};
</script>

<style>
</style>