<template>
  <div>
    <v-dialog
      v-model="value"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card class="grey darken-3 meuDialog" height="100%">
        <v-toolbar dark>
          <v-toolbar-title>Desenhos / Teste de cameras sem IA ({{camera.name}})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- botão inica e para -->
            <v-btn text @click="getImage" :color="running ? 'red' : 'green'">
              <v-icon class="mr-2">
                {{ running ? "mdi-stop" : "mdi-play" }}
              </v-icon>
              {{ running ? "Parar Video" : "Iniciar Video" }}
            </v-btn>
            <!-- botão de limpar desenho -->
            <v-btn color="orange" text @click="clearCanvas(true)">
              <v-icon class="mr-2">mdi-broom</v-icon> Limpar Area
            </v-btn>
            <!-- botão de fechar -->
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- corpo do card -->
        <v-card-text class="mt-2">
          <!-- linha do canvas -->
          <v-row align="center" justify="center">
            <!-- canvas com o "video" -->
            <v-col cols="9" id="colsImg" ref="colsImg" align-self="start">
              <canvas
                id="video"
                ref="video"
                class="canvas"
                @click="clickDraw"
                @contextmenu="undo"
              ></canvas>
              <small v-show="resolucao.width">
                Resolução da Imagem: {{ this.resolucao.width }}x{{
                  this.resolucao.height
                }}
              </small>
            </v-col>
            <!-- tabela com os desenhos do banco -->
            <v-col align-self="start">
              <!-- tabela com os desenhos -->
              <v-simple-table dark dense class="mb-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th colspan="2" class="text-center">
                        <p class="ma-0 pa-0 v-card__subtitle">
                          Poligonos cadastrados
                        </p>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left">Cadastrado</th>
                      <th class="text-left">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="d in draws" :key="d.id">
                      <td>
                        <small>
                          {{ new Date(d.createdAt).toLocaleString() }}
                        </small>
                      </td>
                      <td>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="converToPoints(d.payload)"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteDraw(d)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Excluir</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- botao para adicionar desenho -->
              <v-btn
                class="mb-2"
                block
                @click="save"
                :disabled="points.length < 4"
                >Adicionar Desenho</v-btn
              >
              <!-- card dos objetos encontrados -->
              <v-card>
                <v-card-subtitle>Encontrados</v-card-subtitle>
                <v-card-text v-show="objects.length">
                  <v-chip
                    v-for="ob in objects"
                    small
                    :color="ignoreObjects.includes(ob) ? 'red' : 'green'"
                    :key="ob"
                    class="ma-1"
                    @click="addIgnoreObject(ob)"
                  >
                    {{ ob }}
                  </v-chip>
                </v-card-text>
              </v-card>
              <!-- card dos Alarmes -->
              <v-card class="my-2">
                <v-card-subtitle>Alarmes</v-card-subtitle>
                <v-card-text v-show="alarms.length">
                  <v-chip
                    v-for="(alarm, i) in alarms"
                    :key="i"
                    small
                    class="ma-1"
                    color="red"
                    @click="addIgnoreObject(alarm.c)"
                  >
                    {{ `${alarm.c} - ${(alarm.s * 100).toFixed(2)}%` }}
                  </v-chip>
                </v-card-text>
              </v-card>
              <!-- card dos objetos Ignorados -->
              <v-card class="my-2">
                <v-col align-self="center">
                  <v-row>
                    <p class="v-card__subtitle mt-1">Ignorados</p>
                    <v-checkbox label="Mostrar" v-model="showIgnored" />
                    <v-spacer></v-spacer>
                    <p class="mt-3">
                    <ToolTipIcon
                      text="Salvar as exceções"
                      icon="mdi-content-save"
                      top="true"
                      @click="saveExceptionsCam"
                    />

                    </p>
                  </v-row>
                </v-col>
                <v-card-text v-show="ignoreObjects.length">
                  <v-chip
                    v-for="iob in ignoreObjects"
                    :key="iob"
                    class="ma-1"
                    small
                    close
                    @click:close="addIgnoreObject(iob)"
                  >
                    {{ iob }}
                  </v-chip>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Colision from "@/Utils/Collision.js";
import Parser from "@/Utils/x-mixed-replace-parser.js";
import ToolTipIcon from "../../../components/ToolTipIcon.vue";
export default {
  name: "DialogSnapshot",
  props: ["value", "cameraId", "cameraName", "camera"],
  components: {
    ToolTipIcon,
  },
  computed: {
    ...mapGetters(["getWindowSize"]),
  },
  data: () => ({
    img: null,
    resolucao: { width: 0, height: 0 },
    sizes: {},
    request: null,
    parser: null,
    abortController: null,
    canvas: null,
    context: null,
    cardBody: {},
    running: false,
    objects: [],
    ignoreObjects: [],
    points: [],
    colors: {
      detected: "#00FF00",
      ignored: "#000000",
      alarm: "#FF0000",
      drawed: "#0000FF",
      opacity: "19",
    },
    alarms: [],
    draws: [],
    boundaryDections: null,
    showIgnored: true,
  }),
  methods: {
    // funçãoq que é chamada quando o dialog é fechado e reseta as variaveis
    close() {
      this.type = "";
      this.$emit("input", false);
      this.img = "";
      this.abortController.abort();
      this.points = [];
      this.objects = [];
      this.alarms = [];
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ignoreObjects = [];
      this.draws = [];
      this.boundaryDections = null;
    },
    // função que busca os desenhos cadastrados desta camera
    getDraws() {
      this.$http.get("/desenhoCameras/" + this.cameraId).then((res) => {
        this.draws = res.data;
        // this.drawAll();
      });
    },
    // faz a conexão com o servidor e pega a imagem e as detecções
    async getImage() {
      if (this.running) {
        this.abortController.abort();
        this.abortController = new AbortController();
        this.running = false;
        return;
      }
      this.running = true;
      // pegando a url padrão
      await fetch(`${this.$http.defaults.baseURL}realTime/${this.cameraId}`, {
        method: "GET",
        // formato para receber o arraybuffer
        responseType: "arraybuffer",
        // abort controller para cancelar a requisição
        signal: this.abortController.signal,
        headers: {
          // pegando a autorização do axios
          Authorization: this.$http.defaults.headers.common["Authorization"],
        },
      })
        .then(async (response) => {
          // pega o boundary
          const boundary = response.headers
            .get("Content-Type")
            .split("boundary=")[1];
          this.parser.setBoundary(boundary);
          // inicia o reader
          const reader = response.body.getReader();
          // fica no loop até terminar o reader ou o abort for chamado
          let run = true;
          do {
            const { done, value } = await reader.read();
            // caso o reader tenha terminado ou o abort for chamado, sai do loop
            if (done) run = false;
            if (this.abortController.signal.aborted) run = false;
            // caso ainda esteja rodando, adiciona o chunk no parser
            if (run) {
              this.parser.addChunk(Buffer.from(value, "binary"));
            }
          } while (run);
          this.running = false;
        })
        .catch((error) => {
          this.abortController.abort();
          this.abortController = new AbortController();
          this.running = false;
          console.log("Erro ou abort", error);
        });
    },
    // busca a imagem do servidor
    async getSnapshot() {
      this.$http
        .post(
          "/snapshot",
          { id: this.cameraId },
          {
            // .get("snapshot?id=" + id, {
            responseType: "arraybuffer",
          }
        )
        .then((img) => {
          // img.headers["content-type"];
          // img.data;
          this.updatePic({
            contentType: img.headers["content-type"],
            data: img.data,
          });
        })
        .catch(() => {
          // console.log(err);
          this.loadingImage = false;
        });
    },
    // atualiza a imagem no canvas
    updatePic(boundary) {
      // cria uma nova imagem
      let image = new Image();
      image.onload = () => {
        this.resolucao.width = image.width;
        this.resolucao.height = image.height;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.context.drawImage(
          image,
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
        // adicionando as detecções apos a imagem ser carregada
        this.updateDetection(this.boundaryDections);
      };
      // gera o base64 da imagem, baseado no boundary
      this.img =
        "data:" +
        boundary.contentType +
        ";base64," +
        Buffer.from(boundary.data).toString("base64");
      // injeto a imagem no canvas
      image.src = this.img;
    },
    // desenha os dados recebidos do servidor
    updateDetection(boundary) {
      if (!boundary) return;
      let data = JSON.parse(boundary.data);
      let xp = this.canvas.width / this.resolucao.width;
      let yp = this.canvas.height / this.resolucao.height;
      if (data.result.size) {
        this.objects = [];
        return;
      }
      this.alarms = [];
      let objects = [];
      if (Array.isArray(data.result)) {
        data.result.forEach((element) => {
          element.predictions.forEach((prediction) => {
            // se possuir target event, verifica se o objeto esta na lista
            if(Array.isArray(this.camera.config.targetEvent) && this.camera.config.targetEvent.length){
              if(!this.camera.config.targetEvent.includes(prediction.c)) return;
            }
            // adicionado a lista de objetos encontrados
            objects.includes(prediction.c) ? null : objects.push(prediction.c);
            // verificando se o objeto deve ser ignorado ou não
            let ignored = this.ignoreObjects.includes(prediction.c);
            // se o objeto for ignorado e a opção de mostrar ignorados estiver desativada, não desenha
            if (ignored && !this.showIgnored) return;
            // mudando a cor de acordo com se o objeto foi ignorado ou não
            let color = ignored ? this.colors.ignored : this.colors.detected;
            // se a area possuir mais que 3 pontos e não estiver ignorado, verifica se houve colisão
            if (this.points.length > 3 && !ignored) {
              color =
                Colision.verifyAlarm(
                  { type: 2, coords: this.points },
                  {
                    x: prediction.x * xp,
                    y: prediction.y * yp,
                    w: prediction.w * xp,
                    h: prediction.h * yp,
                    ct: { x: prediction.ct.x * xp, y: prediction.ct.y * yp },
                  }
                ) && !ignored
                  ? this.colors.alarm
                  : color;
              // adiciona o alarme na lista de alarmes
              if (color == this.colors.alarm) {
                this.alarms.push(prediction);
              }
            }
            // inicia o desenho
            this.context.beginPath();
            // desenha a caixa
            this.context.strokeStyle = color;
            this.context.strokeRect(
              prediction.x * xp,
              prediction.y * yp,
              prediction.w * xp,
              prediction.h * yp
            );
            // cor do preenchimento
            this.context.fillStyle = color + this.colors.opacity;
            // aplica o preenchimento
            this.context.fillRect(
              prediction.x * xp,
              prediction.y * yp,
              prediction.w * xp,
              prediction.h * yp
            );
            this.context.stroke();
            this.context.closePath();
            //  texto
            this.context.lineWidth = 2;
            this.context.strokeStyle = color;
            this.context.font = "15px Arial";
            this.context.strokeText(
              `${prediction.c} - ${(prediction.s * 100).toFixed(2)}%`,
              prediction.x * xp,
              prediction.y * yp - 10
            );
          });
          // redesenha os poligonos no canvas
          this.draw(this.points);
        });
      }
      this.objects = objects;
    },
    // adiciona ou remove um objeto da lista de ignorados
    addIgnoreObject(object) {
      this.ignoreObjects.includes(object)
        ? this.ignoreObjects.splice(this.ignoreObjects.indexOf(object), 1)
        : this.ignoreObjects.push(object);
    },
    // pega os clicks para desenhar o poligono que fara a detecção
    clickDraw(value) {
      if (this.finish) {
        this.points.push([this.points[0][0], this.points[0][1]]);
        this.draw(this.points);
      } else {
        this.points.push([value.offsetX, value.offsetY]);
        this.draw(this.points);
      }
    },
    // desenha o poligono que fara a detecção
    draw(points) {
      let context = this.context;
      context.beginPath();
      points.forEach((e, i) => {
        // largura da linha
        context.lineWidth = 3;
        context.strokeStyle = this.colors.drawed;
        if (!i) {
          if (this.finish) {
            context.moveTo(e[0], e[1]);
          } else {
            context.arc(e[0], e[1], 5, 0, 2 * Math.PI);
            context.moveTo(e[0], e[1]);
          }
        } else {
          context.lineTo(e[0], e[1]);
        }
      });
      context.stroke();
      context.closePath();
    },
    // desenha todos os poligonos que foram desenhados,
    drawAll(points) {
      // se vier um array de pontos, desenha todos, se não, cria um array com uma posição
      let draws = Array.isArray(points) ? points : [points];
      draws.forEach((points) => {
        // let ppoints = this.getProportionalSize(points.payload, true);
        this.context.beginPath();
        points.payload.forEach((e, i) => {
          // largura da linha
          this.context.lineWidth = 3;
          // cor da linha
          this.context.strokeStyle = "rgba(0, 255, 0, 1)";
          if (!i) {
            if (this.finish) {
              this.context.moveTo(e[0], e[1]);
            } else {
              this.context.arc(e[0], e[1], 5, 0, 2 * Math.PI);
              this.context.moveTo(e[0], e[1]);
            }
          } else {
            this.context.lineTo(e[0], e[1]);
          }
        });
        this.context.stroke();
        this.context.closePath();
      });
    },
    // limpa o canvas e desenha a imagem
    clearCanvas(clearPoints = false) {
      if (clearPoints) {
        this.points = [];
      }
      let image = new Image();
      image.onload = () => {
        this.resolucao.width = image.width;
        this.resolucao.height = image.height;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.context.drawImage(
          image,
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
      };
      image.src = this.img;
    },
    // converte os pontos do banco para o tamanho do canvas, e desenha, assim consegue verificar alarme
    converToPoints(points) {
      let xp = this.canvas.width / 512;
      let yp = this.canvas.height / 512;
      this.points = [];
      points.forEach((e) => {
        this.points.push([e[0] * xp, e[1] * yp]);
      });
      this.draw(this.points);
    },
    // volta uma ação no desenho
    undo(e) {
      e.preventDefault();
      this.points.pop();
      this.clearCanvas();
      setTimeout(() => {
        this.draw(this.points);
      }, 100);
      // this.draw(this.points, true);
    },
    // converte novamente para pontos do banco e salva
    save() {
      // converte os pontos para o tamanho do banco
      let xp = this.canvas.width / 512;
      let yp = this.canvas.height / 512;
      let points = [];
      this.points.forEach((e) => {
        points.push([e[0] / xp, e[1] / yp]);
      });
      // gera o payload que o banco espera
      let payload = {
        type: 2,
        cameraId: this.cameraId,
        payload: points,
      };
      // faz a requisição para salvar
      this.$http.post("/desenhoCameras", payload).then(() => {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showSuccessSnack", "Desenho salvo com sucesso");
        this.getDraws();
      });
    },
    // apaga o desenho do banco
    deleteDraw(item) {
      this.$http.delete("/desenhoCameras/" + item.id).then(() => {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showSuccessSnack",
          "Desenho Deletado com Sucesso"
        );
        this.getDraws();
      });
    },
    // faz a atualização da camera
    saveExceptionsCam() {
      this.camera.config.exceptions = this.ignoreObjects;
      this.$store.dispatch("saveCamera", {
        ...this.camera,
        cb: () => {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch(
            "showSuccessSnack",
            "Exceções salvas com sucesso"
          );
        },
      });
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.abortController = new AbortController();
        setTimeout(() => {
          this.canvas = document.getElementById("video");
          this.context = this.canvas.getContext("2d");
          this.canvas.width = parseInt(
            getComputedStyle(this.$refs.video).width
          );
          this.canvas.height = parseInt(
            getComputedStyle(this.$refs.video).height
          );
          this.getDraws();
          // se tiver exceções, coloca no array
          if (Array.isArray(this.camera.config.exceptions)) {
            this.ignoreObjects = this.camera.config.exceptions;
          }
          this.getSnapshot();
        }, 500);
      } else {
        this.close();
      }
    },
  },
  mounted() {
    // se não tiver o parser, cria um novo
    if (!this.parser) {
      this.parser = new Parser();
      // escuta o evento de boundary
      this.parser.on("boundary", (boundary) => {
        if (boundary.contentType == "image/jpeg") {
          this.updatePic(boundary);
        } else if (boundary.contentType == "text/plain") {
          // se vier um texto, é porque foi detectado movimento
          let incomming = boundary.data.toString();
          let text = "";
          text = incomming == "motion" ? "Movimento Detectado" : text;
          text = incomming == "started" ? "Video inciado" : text;
          text = incomming == "error" ? "Erro na camera" : text;
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSnack", text);
        } else if (boundary.contentType == "application/json") {
          // this.updateDetection(boundary);
          this.boundaryDections = boundary;
        }
      });
    }
  },
};
</script>

<style scoped>
#video {
  border: 1px solid;
  width: 100%;
  height: 80vh;
  cursor: crosshair;
}
</style>