<template>
  <div>
    <v-card outlined>
      <v-card-title> Configurações CARPS </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-checkbox
              label="Ativar função CARPS"
              v-model="carpsConfig.enabled"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              icon
              @click="addRuleDialog = true"
              :disabled="!carpsConfig.enabled"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="carpsConfig.rules.length > 0">
          <v-col>
            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th class="text-left">Objeto alvo</th>
                    <th class="text-left">Confiança</th>
                    <th class="text-left">Tempo para verificar</th>
                    <th class="text-left">Quantidade esperada</th>
                    <th class="text-left">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in carpsConfig.rules" :key="index">
                    <td>{{ item.class }}</td>
                    <td>{{ item.confidence }} %</td>
                    <td>{{ item.timeoutToCheck }} Segundos</td>
                    <td>
                      Minimo: {{ item.countMin }} - Maximo:
                      {{ item.countMax }}
                    </td>
                    <td>
                      <v-btn icon @click="edtRule(index)">
                        <v-icon color="warning">mdi-pen</v-icon>
                      </v-btn>
                      <v-btn icon @click="carpsConfig.rules.splice(index, 1)">
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- dialog para adicionar regra -->
    <v-dialog v-model="addRuleDialog" width="500" dark>
      <v-card>
        <v-card-title class="text-h5">
          {{ modelRuleCarps.index > -1 ? "Editar" : "Adicionar" }} Regra
        </v-card-title>
        <v-card-text>
          <v-form ref="formCarps">
            <v-autocomplete
              :items="coconames"
              label="Objeto alvo"
              v-model="modelRuleCarps.class"
              :rules="val.requiredField"
              outlined
            ></v-autocomplete>
            <v-text-field
              label="Confiança mínima para detecção"
              v-model.number="modelRuleCarps.confidence"
              outlined
              suffix="%"
              step="10"
              min="10"
              max="90"
              :rules="val.reqFieldNumberMinMax(10, 90)"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Tempo de espera para a checagem"
              v-model.number="modelRuleCarps.timeoutToCheck"
              outlined
              suffix="Segundos"
              step="10"
              min="30"
              :rules="val.reqFieldNumberMinMax(30, 600)"
              max="600"
              type="number"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-text-field
                  label="Quantidade de objetos minimo"
                  v-model.number="modelRuleCarps.countMin"
                  outlined
                  min="1"
                  max="10"
                  :rules="val.reqFieldNumberMinMax(1, 10)"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Quantidade de objetos maximo"
                  v-model.number="modelRuleCarps.countMax"
                  outlined
                  min="1"
                  max="10"
                  :rules="val.reqFieldNumberMinMax(1, 10)"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="addRuleDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="success" text @click="addRule(modelRuleCarps.index)">
            {{ modelRuleCarps.index > -1 ? "Editar" : "Adicionar" }} Regra
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Validator from "@/Utils/InputValidation";
export default {
  name: "ConfigCarps",
  props: {
    carpsConfig: {
      type: Object,
      required: true,
    },
    coconames: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addRuleDialog: false,
      modelRuleCarps: {
        class: "",
        confidence: 30,
        countMax: 1,
        countMin: 1,
        timeoutToCheck: 180,
      },
      val: Validator,
    };
  },
  methods: {
    addRule(index) {
      if (!this.$refs.formCarps.validate()) {
        return;
      }
      // se existir o index, é uma edição
      if (typeof index == "number") {
        // deletar o index do model
        delete this.modelRuleCarps.index;
        // substituir o item no array
        this.carpsConfig.rules[index] = this.modelRuleCarps;
      } else {
        // se não existir o array de regras, cria um
        if (!Array.isArray(this.carpsConfig.rules)) {
          this.carpsConfig.rules = [];
        }
        // adicionar o item no array
        this.carpsConfig.rules.push(this.modelRuleCarps);
      }
      this.cancel();
    },
    edtRule(index) {
      // setar o model com o item do array
      this.modelRuleCarps = this.carpsConfig.rules[index];
      // setar o index para edição
      this.modelRuleCarps.index = index;
      this.addRuleDialog = true;
    },
    // resetar o model
    cancel() {
      this.modelRuleCarps = {
        class: "",
        confidence: 30,
        timeoutToCheck: 30,
        countMax: 1,
        countMin: 1,
      };
      this.addRuleDialog = false;
      this.$refs.formCarps.resetValidation();
    },
  },
};
</script>

<style>
</style>