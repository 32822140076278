<template>
  <div>
    <v-card :loading="getLoadingCam">
      <v-card-title>
        Câmeras {{ getClienteNameById($route.query.clientId) }}
        <v-spacer></v-spacer>
        <v-row no-gutters justify="space-around">
          <v-chip>
            Quantidade: {{ getCamerasFilter.length }} /
            {{ $store.getters.getCameras.length }}
          </v-chip>
          <v-chip>
            Ativas: {{ getActive }} /
            {{ getCamerasFilter.length }}
          </v-chip>
          <v-chip>
            Conectadas: {{ getConnected }} /
            {{ getActive }}
          </v-chip>
        </v-row>
        <v-spacer></v-spacer>
        <!-- BOTÃO FILTRO -->
        <v-btn text @click="dialogFilter = true">
          <v-badge
            :value="isFilterActive"
            bottom
            dot
            overlap
            color="warning  "
            offset-x="12"
          >
            <v-icon class="mr-1">mdi-filter-variant</v-icon>
          </v-badge>
          Filtrar câmeras
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="openDialoag()">
          <!-- :disabled="!$route.query.clientId" -->
          Adicionar Câmera
        </v-btn>
      </v-card-title>
    </v-card>
    <!-- TABELA -->
    <TableCameras
      :items="getCamerasFilter"
      @verCamera="openDialogVerCamera"
      @openDialoag="openDialoag"
      @openDialoagAgenda="openDialoagAgenda"
      @openCamera="openCamera"
      @openDialogConfig="openDialogConfig"
      @openDialogDraw="openDialogDraw"
      @openDialogStart="openDialogStart"
      @openDialogSnap="openDialogSnap"
      @openDialogVideo="openDialogVideo"
      @monitoramento="monitoramento"
      @action="openDialogAction"
      @ativa="ativa"
      @viewEvt="viewEvt"
    />
    <!-- DIALOG FILTRO -->
    <DialogFilter v-model="dialogFilter" :isFilterActive="isFilterActive" />
    <!-- DIALOG AGENDA -->
    <DialogAgenda
      v-model="dialogAgenda"
      :camera="camera"
      @closeDialoagAgenda="closeDialoagAgenda"
      @saveAgendamento="saveAgendamento"
    />
    <!-- <v-dialog v-model="dialogAgenda" persistent max-width="1100px" light>
      <v-card>
        <v-card-title>
          <span class="text-h5">Agenda da Câmera "{{ camera.name }}"</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="2">
                <v-text-field
                  outlined
                  label="Inicio"
                  v-model="agendamento.start"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="">
                <v-autocomplete
                  outlined
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  :items="daysWeek"
                  label="Dia da Semana"
                  v-model="agendamento.daysWeek"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  outlined
                  label="Fim"
                  v-model="agendamento.end"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="1"
                ><v-btn color="success" text @click="saveAgendamento">
                  Salvar
                </v-btn></v-col
              >
            </v-row>
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Inicio</th>
                        <th class="text-left">Dia da Semana</th>
                        <th class="text-left">Final</th>
                        <th class="text-left">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in $store.getters.getAgendamentosCameras"
                        :key="item.id"
                      >
                        <td>{{ item.start }}</td>
                        <td>
                          <v-chip
                            v-for="(it, idx) in item.daysWeek"
                            :key="idx"
                            class="ma-1"
                          >
                            {{ daysWeek.find((e) => e.value == it).text || "" }}
                          </v-chip>
                        </td>
                        <td>{{ item.end }}</td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="agendamento = item"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar Agendamento</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  $store.dispatch('deletAgendamentoCam', item)
                                "
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Deletar Agendamento</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialoagAgenda">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- DIALOG CONFIG -->
    <DialogConfig
      v-model="dialogConfig"
      :hash="hash"
      @saveConfig="saveConfig"
      :camera="camera"
    />
    <!-- DIALOG DRAW -->
    <DialogDraw
      v-model="dialogDraw"
      :cameraId="cameraId"
      :cameraName="cameraName"
      :camera="camera"
    />
    <!-- DIALOG SNAP -->
    <DialogSnap
      v-model="dialogSnap"
      :cameraId="cameraId"
      :cameraName="cameraName"
      :camera="camera"
    />
    <!-- DIALOG VIDEO -->
    <DialogVideo
      v-model="dialogVideo"
      :cameraId="cameraId"
      :cameraName="cameraName"
      :camera="camera"
    />
    <!-- DIALOG START -->
    <DialogStart v-model="dialogStart" :camera="camera" />
    <!-- DIALOG VERCAMERA -->
    <VerCamera v-model="dialogVerCamera" :item="camera" />
    <!-- DIALOG VERCAMERA -->
    <FormCamera
      v-model="dialogFormCamera"
      :item="camera"
      @closeDialog="closeDialoag"
      @save="save"
      :clientId="$route.query.clientId || ''"
    />
    <!-- DIALOG AÇÃO -->
    <DialogAction v-model="dialogAction" :camera="camera" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogFilter from "./components/DialogFilter.vue";
import DialogConfig from "./components/DialogConfig.vue";
import DialogDraw from "./components/DialogDraw.vue";
import DialogSnap from "./components/DialogSnapshot.vue";
import DialogVideo from "./components/DialogVideo.vue";
import DialogStart from "./components/DialogStartForce.vue";
import TableCameras from "./components/TableCameras.vue";
import VerCamera from "./components/VerCamera.vue";
import FormCamera from "./components/FormCamera";
import DialogAgenda from "./components/DialogAgenda.vue";
import DialogAction from "./components/DialogAction.vue";
export default {
  name: "Cameras",
  props: ["clientId"],
  components: {
    DialogFilter,
    DialogConfig,
    DialogDraw,
    DialogStart,
    DialogSnap,
    TableCameras,
    VerCamera,
    FormCamera,
    DialogAgenda,
    DialogVideo,
    DialogAction
  },
  data: () => {
    return {
      timerReload: null,
      search: "",
      dialogFilter: false,
      dialogFormCamera: false,
      dialogAgenda: false,
      dialogConfig: false,
      dialogDraw: false,
      dialogStart: false,
      dialogSnap: false,
      dialogVideo: false,
      dialogVerCamera: false,
      dialogAction: false,
      hash: "",
      camera: {
        name: "",
        groupId: 0,
        modelId: 0,
        url: "",
        user: "",
        password: "",
        config: {
          detectionLimits: { width: 0, height: 0 },
          reconectTimeoutSeconds: 0,
          eventsConectionRepeatMinutes: 0,
        },
      },
      cameraId: 0,
      cameraName: "",
      agendamento: {
        start: "00:00",
        end: "23:59",
        daysWeek: [0, 1, 2, 3, 4, 5, 6],
      },
      agendamentos: [],
      daysWeek: [
        { value: 0, text: "Dom" },
        { value: 1, text: "Seg" },
        { value: 2, text: "Ter" },
        { value: 3, text: "Qua" },
        { value: 4, text: "Qui" },
        { value: 5, text: "Sex" },
        { value: 6, text: "Sab" },
      ],
    };
  },
  methods: {
    closeDialoag() {
      this.setCameraObjetcToDefaults();
      // this.camera.
      this.dialogFormCamera = false;
    },
    closeDialoagAgenda() {
      this.agendamento = {};
      this.dialogAgenda = false;
    },
    openDialoag(camera) {
      if (camera) {
        this.camera = camera;
        if (!camera.config) {
          this.camera.config = this.camSelected(camera.modelId);
        }
      }
      this.dialogFormCamera = true;
    },
    openDialogConfig(camera) {
      if (camera) {
        this.camera = camera;
        if (!camera.config) {
          this.camera.config = this.camSelected(camera.modelId);
        }
      }
      this.dialogConfig = true;
    },
    openDialogDraw(camera) {
      this.cameraId = camera.id;
      this.cameraName = camera.name;
      this.camera = camera;
      this.dialogDraw = true;
    },
    openDialogStart(camera) {
      if (camera) {
        this.camera = camera;
        this.dialogStart = true;
      }
    },
    openDialogVerCamera(camera) {
      if (camera) {
        this.camera = camera;
        this.dialogVerCamera = true;
      }
    },
    openDialogSnap(camera) {
      if (camera) {
        this.cameraId = camera.id;
        this.cameraName = camera.name;
        this.camera = camera;
        this.dialogSnap = true;
      }
    },
    openDialogVideo(camera) {
      if (camera) {
        this.cameraId = camera.id;
        this.cameraName = camera.name;
        this.camera = camera;
        this.dialogVideo = true;
      }
    },
    openDialogAction(camera) {
      if (camera) {
        this.cameraId = camera.id;
        this.cameraName = camera.name;
        this.camera = camera;
        this.dialogAction = true;
      }
    },
    openDialoagAgenda(camera) {
      if (camera) {
        this.camera = camera;
      }
      this.dialogAgenda = true;
      this.$store.dispatch("loadAgendamentosByCameraId", camera.id);
    },
    save(camera) {
      if (this.$route.query.clientId) {
        camera.clientId = this.$route.query.clientId;
      }
      this.$store.dispatch("saveCamera", {
        ...camera,
        cb: () => {
          if (this.$route.query.clientId) {
            this.$store.dispatch(
              "loadCamerasByClientId",
              this.$route.query.clientId
            );
          } else {
            this.$store.dispatch("loadCameras");
          }
        },
      });
      this.closeDialoag();
    },
    saveConfig() {
      this.$store.dispatch("saveCamera", {
        ...this.camera,
        cb: () => {
          if (this.$route.query.clientId) {
            this.$store.dispatch(
              "loadCamerasByClientId",
              this.$route.query.clientId
            );
          } else {
            this.$store.dispatch("loadCameras");
          }
        },
      });
      this.dialogConfig = false;
    },
    saveAgendamento(agendamento) {
      let payload = {
        cameraId: this.camera.id,
        // ...this.agendamento,
        ...agendamento,
        cb: () => {
          this.$store.dispatch("loadAgendamentosByCameraId", this.camera.id);
        },
      };
      // console.log(this.camera, this.agendamento);
      this.$store.dispatch("saveAgendamentoCam", payload);
    },
    del(item) {
      this.$store.dispatch("deletCamera", item);
    },
    openCamera(item) {
      let win = window.open(item.url, "_blank");
      win.focus();
    },
    ativa(item) {
      // console.log(item);
      let payload = {
        id: item.id,
        enable: item.deletedAt ? true : false,
        clientId: item.clientId,
      };
      // console.log(payload);
      // enableCamera
      this.$store.dispatch("enableCamera", {
        ...payload,
        cb: () => {
          if (this.$route.query.clientId) {
            this.$store.dispatch(
              "loadCamerasByClientId",
              this.$route.query.clientId
            );
          } else {
            this.$store.dispatch("loadCameras");
          }
        },
      });
    },
    monitoramento(item) {
      this.$store.dispatch("saveCamera", {
        ...item,
        sendEvent: !item.sendEvent,
        cb: () => {
          if (this.$route.query.clientId) {
            this.$store.dispatch(
              "loadCamerasByClientId",
              this.$route.query.clientId
            );
          } else {
            this.$store.dispatch("loadCameras");
          }
        },
      });
    },
    edtAgendamento(ag) {
      this.agendamento = {
        ...ag,
        daysWeek: JSON.parse(ag.daysWeek),
      };
    },
    getColorRow(item) {
      if (item.deletedAt) {
        return "";
      }
      if (this.$store.getters.getCameraStatusById(item.id).conected) {
        return "green darken-4";
      } else {
        return "red darken-4";
      }
    },
    getData() {
      if (this.$route.query.clientId) {
        this.$store.dispatch(
          "loadCamerasByClientId",
          this.$route.query.clientId
        );
      } else {
        this.$store.dispatch("loadCameras");
      }
      this.$store.dispatch("loadCameraStatus");
      // this.$store.dispatch("loadClientes");
      // this.$store.dispatch("loadGrupos");
      // this.$store.dispatch("loadCamTypes");
    },
    camSelected(value) {
      let camera = this.$store.getters.getCamerasTypes.find(
        (e) => e.value == value
      );
      // console.log(value, camera);
      if (camera) {
        this.camera.config = camera.config;
        return camera.config;
        // console.log(this.camera);
        // let config = this.cameraConfigParser(camera.config);
        // console.log(config);
      }
    },
    cameraConfigParser(cameraConfig) {
      let obj = {};
      for (const key in cameraConfig) {
        if (Object.hasOwnProperty.call(cameraConfig, key)) {
          const element = cameraConfig[key];
          // console.log(Object.prototype.toString.call(cameraConfig[key]));
          if (
            Object.prototype.toString.call(cameraConfig[key]) ===
            "[object Object]"
          ) {
            obj = { ...this.cameraConfigParser({ ...element }), ...obj };
            // console.log(key, JSON.stringify(element));
          } else {
            // console.log(`{"${key}":"${element}"}`);
            obj[key] = element;
          }
        }
      }
      return obj;
    },
    setCameraObjetcToDefaults() {
      this.camera = {
        name: "",
        groupId: 0,
        modelId: 0,
        url: "",
        user: "",
        password: "",
        config: {
          detectionLimits: { width: 0, height: 0 },
          reconectTimeoutSeconds: 0,
          eventsConectionRepeatMinutes: 0,
        },
      };
    },
    searchCam(value) {
      this.$store.dispatch("setFilterCam", value || "");
    },
    viewEvt(item) {
      this.$router.push({
        path: "/eventos",
        params: {
          clientId: "kkk",
        },
        query: {
          cameraId: item.id,
        },
      });
    },
  },
  computed: {
    getCameras: {
      get() {
        return this.$store.getters.getCameras;
      },
      set() {},
    },
    getCamerasFilter: {
      get() {
        return this.$store.getters.getCamerasFilter;
      },
      set() {},
    },
    getCamerasTypes: {
      get() {
        return this.$store.getters.getCamerasTypes;
      },
      set() {},
    },
    ...mapGetters(["getClienteNameById", "getLoadingCam"]),
    getConnected() {
      return this.getCamerasFilter.filter(item => this.$store.getters.getCameraStatusById(item.id).conected).length;
    },
    getActive() {
      return this.getCamerasFilter.filter(item => !item.deletedAt).length;
    },
    isFilterActive(){
      return (
        this.$store.getters.getFilterCamModel.length > 0 ||
        this.$store.getters.getFilterCamClient.length > 0 ||
        this.$store.getters.getFilterCamGroup.length > 0 ||
        this.$store.getters.getFilterCam != "" ||
        this.$store.getters.getFilterCamActive ||
        this.$store.getters.getFilterCamProxy != ""
      )
    }
  },
  mounted() {
    this.getData();
    this.timerReload = setInterval(this.getData, 10000);
    this.$store.dispatch("loadClientes");
    this.$store.dispatch("loadGrupos");
    this.$store.dispatch("loadCamTypes");
    this.$store.dispatch("loadProxys");
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timerReload);
    next();
  },
};
</script>

<style scoped>
.over {
  overflow: auto;
  height: 100vh;
  width: 100vw;
}
* {
  scrollbar-width: thin;
  scrollbar-color: gray rgb(82, 82, 82);
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: rgb(161, 161, 161);
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid rgb(82, 82, 82);
}
</style>