<template>
  <div>
    <v-dialog v-model="value" persistent light scrollable>
      <v-card>
        <v-card-title>
          <span class="text-h5">Agenda da Câmera "{{ camera.name }}"</span>
          <v-spacer></v-spacer>
          <small>
            *{{ agendamento.id ? "Editar agendamento" : "Novo agendamento" }}
          </small>
        </v-card-title>
        <v-card-text class="pt-2">
          <!-- linha do form -->
            <v-row>
              <v-col cols="auto">
                <v-text-field
                  outlined
                  label="Inicio"
                  v-model="agendamento.start"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  outlined
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  :items="daysWeek"
                  label="Dia da Semana"
                  v-model="agendamento.daysWeek"
                ></v-autocomplete>
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  outlined
                  label="Fim"
                  v-model="agendamento.end"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="success"
                  text
                  block
                  x-large
                  outlined
                  @click="save"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
            <!-- linha da tabela -->
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Inicio</th>
                        <th class="text-left">Dia da Semana</th>
                        <th class="text-left">Final</th>
                        <th class="text-left">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in $store.getters.getAgendamentosCameras"
                        :key="item.id"
                      >
                        <td>{{ item.start }}</td>
                        <td>
                          <v-chip
                            v-for="(it, idx) in item.daysWeek"
                            :key="idx"
                            class="ma-1"
                          >
                            {{ daysWeek.find((e) => e.value == it).text || "" }}
                          </v-chip>
                        </td>
                        <td>{{ item.end }}</td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="agendamento = item"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar Agendamento</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  $store.dispatch('deletAgendamentoCam', item)
                                "
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Deletar Agendamento</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="warning" text @click="resetForm">
            Cancelar Edição
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogAgenda",
  props: ["value", "camera"],
  data: () => ({
    agendamento: {
      start: "00:00",
      end: "23:59",
      daysWeek: [0, 1, 2, 3, 4, 5, 6],
    },
    daysWeek: [
      { value: 0, text: "Dom" },
      { value: 1, text: "Seg" },
      { value: 2, text: "Ter" },
      { value: 3, text: "Qua" },
      { value: 4, text: "Qui" },
      { value: 5, text: "Sex" },
      { value: 6, text: "Sab" },
    ],
  }),
  methods: {
    save() {
      this.$emit("saveAgendamento", this.agendamento);
      this.resetForm();
      this.loadByCamId();
    },
    delete() {
      this.agendamento.cameraId = this.camera.id;
      this.$emit("deleteAgendamento", this.agendamento);
      this.resetForm();
      setTimeout(() => {
        this.loadByCamId();
      }, 1000);
    },
    loadByCamId() {
      this.$store.dispatch("loadAgendamentosByCameraId", this.camera.id);
    },
    resetForm() {
      this.agendamento = {
        start: "00:00",
        end: "23:59",
        daysWeek: [0, 1, 2, 3, 4, 5, 6],
      };
    },
    close() {
      this.$emit("closeDialoagAgenda");
      this.resetForm();
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        this.resetForm();
        this.loadByCamId();
      }
    },
  },
};
</script>

<style scoped>
</style>