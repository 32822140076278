<template>
  <!-- tooltip do status -->
  <v-tooltip left color="grey darken-4">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>
    </template>
    <!-- card com os dados -->
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col>
            Ultimo Evento:
            {{
              $store.getters.getCameraStatusById(item.id).lastEvent
                ? new Date(
                    $store.getters.getCameraStatusById(item.id).lastEvent
                  ).toLocaleString()
                : "Sem eventos desde a última reinicialização"
            }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            Conectada:
            {{
              $store.getters.getCameraStatusById(item.id).conected
                ? "SIM"
                : "NÃO"
            }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            Ativa:
            {{
              item.deletedAt ? 'Não' : 'Sim'
            }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            Enviando eventos:
            {{
              item.sendEvent ? 'Sim' : 'Não'
            }}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            Modelo:
            {{ $store.getters.getCameraTypeById(item.modelId)}}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tooltip>
</template>

<script>
export default {
  name:'StatusToolTip',
  props:{
    item:{
      type:Object,
      default:()=>{}
    }
  },
};
</script>

<style>
</style>