<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ item.id ? "Editar" : "Cadastrar" }} câmera
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <v-card-text>
            <v-form ref="formCamera">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome da Camera"
                    v-model="item.name"
                    :rules="reqFieldMinLength(6)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    :items="this.$store.getters.getGroupSelect"
                    label="Grupo"
                    v-model="item.groupId"
                    auto-select-first
                    :rules="requiredField"
                    :disabled="disableGroup"
                    :hint="disableGroup ? 'Grupo padrão do cliente' : ''"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="this.$store.getters.getClientesSelect"
                    label="Cliente"
                    v-model="item.clientId"
                    :disabled="disableClient"
                    :rules="requiredField"
                    :hint="disableGroup ? 'Cliente selecionado' : ''"
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="this.$store.getters.getCamerasTypes"
                    label="Modelo da Camera"
                    v-model="item.modelId"
                    @change="camSelected"
                    auto-select-first
                    :rules="requiredField"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <DefaultOptions :camera="item" />
              <FormType5
                :camera="item"
                v-show="item.modelId == 5 || item.modelId == 6"
              />
              <ProfilesOnvif
                :camera="item"
                @validateForm="$refs.formCamera.validate()"
              />
               <v-row v-show="description.length">
              <v-col>
                <v-card outlined>
                  <v-card-title>
                    <span class="headline">Observações</span>
                  </v-card-title>
                  <v-card-text>
                    <div v-html="description"></div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="success" text @click="save"> Salvar </v-btn>
              </v-row>
            </v-form>
           
          </v-card-text>
        </v-card>
      </v-card-text>
      <DialogDguard
        v-model="dialogDguard"
        :client="client"
        :monit="monitoramento"
        :camera="item"
        @import="getDataFromDguard"
      />
      <DialogDigifort
        v-model="dialogDigifort"
        :client="client"
        :monit="monitoramento"
        :camera="item"
        @import="getDataFromDigifort"
      />
      <v-btn
        color="success"
        text
        @click="dialogDguard = true"
        v-show="monitoramento.softwareVmsId == 1"
      >
        Importar do Dguard
      </v-btn>
      <v-btn
        color="success"
        text
        @click="dialogDigifort = true"
        v-show="monitoramento.softwareVmsId == 2"
      >
        Importar do Digifort(SIGMA IMAGEM)
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  requiredField,
  reqFieldMinLength,
} from "../../../Utils/InputValidation";
import FormType5 from "./Type5.vue";
import DefaultOptions from "./DefaultOptions.vue";
import DialogDguard from "./ImportFromDguard.vue";
import DialogDigifort from "./ImportFromDigifort.vue";
import ProfilesOnvif from "./Profiles.vue";
export default {
  name: "FormCamera",
  components: {
    FormType5,
    DefaultOptions,
    DialogDguard,
    ProfilesOnvif,
    DialogDigifort,
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    clientId: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  data() {
    return {
      requiredField,
      reqFieldMinLength,
      client: {},
      monitoramento: {},
      dialogDguard: false,
      dialogDigifort: false,
      disableClient: false,
      disableGroup: false,
      description: "",
    };
  },
  methods: {
    save() {
      if (!this.$refs.formCamera.validate()) {
        return;
      }
      this.$emit("save", this.item);
    },
    close() {
      this.$refs.formCamera.resetValidation();
      this.$emit("closeDialog");
    },
    camSelected(value) {
      let camera = this.$store.getters.getCamerasTypes.find(
        (e) => e.value == value
      );
      if (camera) {
        this.description = camera.description;
        this.item.config = camera.config;
        return camera.config;
      }
    },
    getDataFromDguard(data) {
      this.item.url = data.url;
      this.item.user = data.username;
      this.item.password = data.password;
      this.item.modelId = 9;
      this.item.name = `${data.server.name} - ${data.camera.name}`;
      this.camSelected(7);
    },
    getDataFromDigifort(data) {
      this.item.url = data.url;
      this.item.user = data.username;
      this.item.password = data.password;
      this.item.modelId = 9;
      this.item.name = `${data.cameraName}`;
      this.camSelected(9);
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        let cliId = parseFloat(this.clientId);
        if (typeof cliId == "number" && cliId > 0) {
          // se tiver cliente selecionado seto os valores
          this.item.clientId = cliId;
          this.disableClient = true;
          // pego o cliente e o monitoramento
          this.client = this.$store.getters.getClienteById(this.clientId);
          // se o cliente tiver grupo seto o grupo
          if (Number.isInteger(this.client.groupId)) {
            this.item.groupId = this.client.groupId;
            this.disableGroup = true;
          }
          this.monitoramento = this.$store.getters.getMonitoramentoById(
            this.client.moniId
          );
        }
        if (this.item.modelId) {
          this.camSelected(this.item.modelId);
        }
        if(this.$store.getters.getGroups.length ==1){
          this.item.groupId = this.$store.getters.getGroups[0].id;
        }
      } else {
        this.description = "";
      }
    },
  },
};
</script>

<style>
</style>