var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{staticClass:"grey darken-3"},[_c('v-card-title',[_vm._v(" Desenhos "+_vm._s(_vm.cameraName)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.types,"label":"Selecione o Tipo de Desenho","hint":'Selecionado ' + _vm.getType(_vm.type)},model:{value:(_vm.desenho.type),callback:function ($$v) {_vm.$set(_vm.desenho, "type", $$v)},expression:"desenho.type"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.valid},on:{"click":_vm.save}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Salvar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.getImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar Imagem")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.clearPathCanvas}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Limpar")])])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingImage),expression:"!loadingImage"}],attrs:{"id":"desenho","width":"380","height":"380"},on:{"click":_vm.clickDraw,"contextmenu":_vm.undo,"mousemove":_vm.monitoringMouse}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingImage),expression:"loadingImage"}],staticClass:"minhaTabela",staticStyle:{"width":"380px","height":"380px","display":"flex","justify-content":"center","align-items":"center"}},[_c('v-progress-circular',{attrs:{"size":300,"color":"primary","indeterminate":""}},[_vm._v(" Carregando snapshot ")])],1),_c('v-simple-table',{staticClass:"mx-2 minhaTabela",attrs:{"dark":"","height":"380"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Tipo")]),_c('th',{staticClass:"text-left"},[_vm._v("Cadastrado")]),_c('th',{staticClass:"text-left"},[_vm._v(" Ações "),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":_vm.drawAll}},[_c('v-icon',[_vm._v("mdi-check-all")])],1)],1)])]),_c('tbody',_vm._l((_vm.draws),function(d){return _c('tr',{key:d.id},[_c('td',[_vm._v(_vm._s(d.id))]),_c('td',[_vm._v(_vm._s(_vm.getType(d.type)))]),_c('td',[_vm._v(_vm._s(new Date(d.createdAt).toLocaleString()))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showDraw(d)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.del(d)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)])}),0)]},proxy:true}])})],1)],1),_c('v-card-actions',[_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.resolucao.width),expression:"resolucao.width"}]},[_vm._v(" Resolução da Câmera: "+_vm._s(this.resolucao.width)+"x"+_vm._s(this.resolucao.height)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }