<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title> Importar câmeras do Digifort </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <v-card-text>
            <v-form ref="formDguard">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-data-table
                :headers="headers"
                :items="getCamerasDigifort"
                :items-per-page="15"
                class="elevation-1"
                :search="search"
                :loading="getLoadingCams"
                loading-text="Carregando câmeras..."
              >
                <template slot="item.actions" slot-scope="{ item }">
                  <ToolTip
                    icon="mdi-image-search"
                    top="true"
                    text="Ver snapshot"
                    @click="getSnapshot(item)"
                  />
                  <ToolTip
                    icon="mdi-image-move"
                    top="true"
                    text="Importar Câmera"
                    @click="useCamera(item)"
                  />
                </template>
              </v-data-table>
            </v-form>
            <!-- card do snapshot -->
            <v-card outlined v-show="$store.getters.getSnapImport">
              <v-card-title>Imagem da camera {{ name }}</v-card-title>
              <v-card-text>
                <v-img :src="$store.getters.getSnapImport"></v-img>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="getCameras" color="success">Atualizar cameras</v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  requiredField,
  reqFieldMinLength,
} from "../../../Utils/InputValidation";
import ToolTip from "../../../components/ToolTipIcon.vue";
export default {
  name: "ImportFromDigifort",
  components: { ToolTip },
  props: {
    item: {
      type: Object,
      required: false,
    },
    client: {
      type: Object,
      required: true,
    },
    monit: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    camera: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogIP: false,
      requiredField,
      reqFieldMinLength,
      search: "",
      headers: [
        {
          text: "Nome da câmera",
          value: "Name",
        },
        { text: "Modelo", value: "Model" },
        { text: "Tipo", value: "DeviceType" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      name: "",
    };
  },
  methods: {
    save() {
      this.$emit("save", this.item);
    },
    close() {
      this.$emit("input", false);
    },
    getSnapshot(item) {
      let payload = {
        cameraName: item.Name,
        clientId: this.client.id,
      };
      this.name = item.Name;
      this.$store.dispatch("getSnapFromDigifort", payload);
    },
    useCamera(item) {
      let payload = {
        cameraName: item.Name,
        clientId: this.client.id,
        cb: (data) => {
          this.$emit("import", { ...data, cameraName: item.Name });
          this.close();
        },
      };
      this.$store.dispatch("getCameraUrlDigifort", payload);
    },
    getCameras() {
      this.$store.dispatch("loadCamerasFromDigifort", this.client.id);
    },
  },
  computed: {
    getCamerasDigifort: {
      get() {
        return this.$store.getters.getCamerasDigifort;
      },
      set() {},
    },
    getLoadingCams: {
      get() {
        return this.$store.getters.getLoadingCams;
      },
      set() {},
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getCameras();
        this.$store.dispatch("setSnapImport", null);
        this.$store.dispatch("setDguardCameras", []);
      }
    },
  },
};
</script>

<style>
</style>