/*
    Classe para fazer o parse dos dados recebidos de uma requisição do tipo x-mixed-replace, 
    onde os dados veem em partes e precisam ser concatenados para serem processados.

    A classe herda de EventEmitter para poder emitir eventos quando um payload for encontrado.

    A classe tem um buffer que vai concatenando os dados recebidos, e quando um payload for encontrado
    ele é removido do buffer principal e emitido um evento com os dados do payload.

    A classe também tem um historico de payloads parseados, onde o tamanho do historico é definido
    no construtor da classe.

    A classe também tem um index que é incrementado a cada payload parseado.

    Desenvolvida por: Mauricio Gomes
    Data: 06/12/2022
    Criada para o projeto Heimdall com cameras da Intelbras
*/

/**
 * Objeto que representa um boundary encontrado no payload
 * @typedef {Object} Boundary
 * @property {String} contentType
 * @property {Number} contentLength
 * @property {Buffer} data
 * @property {Number} index 
 */

/**
 * Objeto que representa um header encontrado no payload
 * @typedef {Object} Header
 * @property {String} contentType
 * @property {Number} contentLength
 * @property {String} contentLengthString
 */

/**
 * Classe para fazer o parse dos dados recebidos de uma requisição do tipo x-mixed-replace,
 * onde os dados veem em partes e precisam ser concatenados para serem processados.
 * @typedef {Class} MultipartXMixedReplace
 * @class MultipartXMixedReplace
 * @extends {EventEmitter}
 * @param {Number} maxHistory - Quantidade maxima de payloads no historico
 */

const EventEmitter = require('events');
module.exports = class MultipartXMixedReplace extends EventEmitter {
    constructor(maxHistory = 5) {
        super();
        /**
         * Buffer que vai concatenando os dados recebidos
         * @type {Buffer}
         */
        this.buffer;
        /**
         * String que representa o boundary
         * @type {String}
         * */
        this.boundary = "--myboundary";
        /** 
         * Numero do boundaries parseados
        */
        this.index = 0;
        /**
         * Ultimo boundary parseado
         * @type {Boundary}
         * */
        this.lastBoundary = {};
        /** 
         * Historico de boundaries parseados
         * @type {Array<Boundary>}
        */
        this.boundaryHistory = [];
        /** 
         * Quantidade maxima de boundaries no historico
         * use com cuidado, pois o historico pode usar muita memoria
         * @type {Number}
        */
        this.maxHistory = maxHistory > 0 ? maxHistory : 5;
    }
    /**
     * Propriedade para adicionar mais uma parte do chunk no buffer
     * @param {Buffer} chunk 
     */
    addChunk = function (chunk) {
        handleData(this, chunk);
    }
    /**
     * Informa o boundary para o parser
     * @param {String} boundary 
     */
    setBoundary = function (boundary) {
        this.boundary = boundary;
    }
    /**
     * Retorna o ultimo parse feito
     * @returns {Object} Boundary
     */
    getLastBoundary = function () {
        return this.boundaryHistory[this.boundaryHistory.length - 1];
    }
    /**
     * Retorna o historico de boundaries parseados de acordo com o maxHistory
     * @returns {Array<Boundary>}
     */
    getBoundaryHistory = function () {
        return this.boundaryHistory;
    }
}

/**
 * 
 * @param {MultipartXMixedReplace} self 
 * @param {Buffer} chunk 
 */
// função para fazer o parse do payload
function handleData(self, chunk) {
    // concatenando os dados recebidos
    if (self.buffer) {
        self.buffer = Buffer.concat([self.buffer, chunk]);
    } else {
        self.buffer = chunk;
    }
    // busco os headers do payload
    let headers = getHeaders(self, self.buffer);
    // percorro os headers e extraio os dados
    headers.forEach(header => {
        let data = getData(self, header);
        // se o payload for encontrado
        if (data != undefined) {
            let boundary = {
                contentType: header.contentType,
                contentLength: header.contentLength,
                data,
                index: self.index++
            }
            // adicionando o boundary no historico
            addBondaryHistory(self, boundary);
            // emitindo o evento de boundary com os dados do payload
            self.emit("boundary", boundary);
        }
    })
}

/**
 * Função que monta o header do buffer e retorna os headers formatados
 * @param {MultipartXMixedReplace} self 
 * @param {Buffer} buffer 
 * @returns {Array<Header>}
 */
function getHeaders(self) {
    // toString no buffer
    let data = self.buffer.toString();
    // pegando o inicio do boundary
    let startBoudary = data.indexOf(`${self.boundary}`);
    // array de retorno
    let dataRTN = [];
    // caso venha o inicio do boundary
    if (startBoudary > -1) {
        // pegandos os content type
        let ct = data.toString().match(/(?<=Content-Type:).*/gi);
        // pegando o content length
        let cl = data.toString().match(/(?<=Content-Length:).*/gi);
        // entregando uma string para encontrar o incio do buffer
        let ca = data.toString().match(/Content-Length:.*/gi);
        // verificando se as duas variaveis foram encontradas e são do mesmo tamanho
        if (Array.isArray(ct) && Array.isArray(cl)) {
            if (ct.length == cl.length) {
                // percorrendo o array de content type
                for (let index = 0; index < ct.length; index++) {
                    // inserindo o content type no array
                    let part = {
                        // content type
                        contentType: ct[index].trim(),
                        // content length
                        contentLength: parseFloat(cl[index]),
                        // passando um string para encontrar o inicio do payload
                        contentPayload: ca[index],
                    }
                    dataRTN.push(part);
                }
            } else {
                // caso o tamanho dos arrays não seja igual retorna um array vazio
                return [];
            }
        }
        return dataRTN
    } else {
        // caso não venha o inicio do boundary retorna um array vazio
        return [];
    }
}

/**
 * Função para extrair os dados do payload
 * @param {MultipartXMixedReplace} self 
 * @param {Header} header 
 * @returns {Buffer} data || undefined
 */
function getData(self, header) {
    // pegando o tamanho do content length adicionando 4 para o /r/n/r/n
    let contentLengthLength = `${header.contentPayload}`.length + 4;
    // console.log("contentLengthLength", contentLengthLength);
    // calculando o inicio do do content
    let contentStartIndex = self.buffer.indexOf(header.contentPayload) + contentLengthLength;
    // calculando o final do content para usar na limpeza do buffer
    let contentEnd = contentStartIndex + header.contentLength;
    // extraio o payload do buffer
    let data = self.buffer.slice(contentStartIndex, contentStartIndex + header.contentLength);
    // verifico se o tamanho do payload é igual ao content length, se for retorno o payload
    if (data.length == header.contentLength) {
        // tirando o payload do buffer principal
        clearBuffer(self, contentEnd, header);
        return data;
    } else {
        return undefined;
    }
}

/**
 * Função usada para remover o payload do buffer principal
 * @param {MultipartXMixedReplace} self 
 * @param {Number} ctEnd 
 */
function clearBuffer(self, ctEnd, header) {
    try {
        // fazendo uma copia do buffer para remover o payload do buffer principal
        let bufTemp = Buffer.alloc(self.buffer.length - ctEnd);
        self.buffer.copy(bufTemp, 0, ctEnd, self.buffer.length);
        self.buffer = bufTemp;

    } catch (error) {
        console.log(error);
        console.log("error to clear buffer", self.buffer.length, header.payloadEnd);
    }
}

/**
 * Adiciona o boundary no historico de boundary recebidos de acordo com o valor maxHistory
 * @param {MultipartXMixedReplace} self 
 * @param {Boundary} boundary 
 */
function addBondaryHistory(self, boundary) {
    self.boundaryHistory.push(boundary);
    if (self.boundaryHistory.length > self.maxHistory) {
        self.boundaryHistory.shift();
    }
}