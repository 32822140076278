<template>
  <div>
    <v-card outlined>
      <v-card-title> Configurações da dupla verificação </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-checkbox label="Ativar dupla verificação" v-model="doubleCheckConfig.enabled" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete :items="classes" label="Objeto alvo" v-model="doubleCheckConfig.classes"
              :rules="val.requiredField" outlined multiple chips small-chips deletable-chips></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field label="Confiança mínima para detecção" v-model.number="doubleCheckConfig.confidence" outlined
              suffix="%" step="10" min="10" max="90" :rules="val.reqFieldNumberMinMax(10, 90)"
              type="number"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Validator from "@/Utils/InputValidation";
export default {
  name: "ConfigCarps",
  props: {
    doubleCheckConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addRuleDialog: false,
      modelRuleCarps: {
        class: "",
        confidence: 30,
        countMax: 1,
        countMin: 1,
        timeoutToCheck: 180,
      },
      val: Validator,
      classes: []
    };
  },
  methods: {
    addRule(index) {
      if (!this.$refs.formCarps.validate()) {
        return;
      }
      // se existir o index, é uma edição
      if (typeof index == "number") {
        // deletar o index do model
        delete this.modelRuleCarps.index;
        // substituir o item no array
        this.carpsConfig.rules[index] = this.modelRuleCarps;
      } else {
        // se não existir o array de regras, cria um
        if (!Array.isArray(this.carpsConfig.rules)) {
          this.carpsConfig.rules = [];
        }
        // adicionar o item no array
        this.carpsConfig.rules.push(this.modelRuleCarps);
      }
      this.cancel();
    },
    edtRule(index) {
      // setar o model com o item do array
      this.modelRuleCarps = this.carpsConfig.rules[index];
      // setar o index para edição
      this.modelRuleCarps.index = index;
      this.addRuleDialog = true;
    },
    // resetar o model
    cancel() {
      this.modelRuleCarps = {
        class: "",
        confidence: 30,
        timeoutToCheck: 30,
        countMax: 1,
        countMin: 1,
      };
      this.addRuleDialog = false;
      this.$refs.formCarps.resetValidation();
    },
  },
  mounted() {
    this.$http("doubleCheck/Classes").then(resp => {
      this.classes = [];
      resp.data.forEach((element, index) => {
        this.classes.push({
          value: index,
          text: element,
        });
      });
    })
  },
};
</script>

<style></style>