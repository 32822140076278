<template>
  <div>
    <v-dialog v-model="value" persistent max-width="800px">
      <v-card outlined>
        <v-card-title>Filtrar câmeras</v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col class="pb-0">
              <v-text-field label="Pesquisar" hint="Pesquise pelo id, nome, usuario, senha, url, zona" persistent-hint
                v-model="filterText" clearable prepend-inner-icon="mdi-magnify" />
            </v-col>
            <v-col class="pb-0" v-show="!$route.query.clientId">
              <v-autocomplete :items="this.$store.getters.getClientesSelect" label="Cliente"
                hint="Selecione um ou mais clientes" v-model="filterCamClient" persistent-hint clearable multiple chips
                prepend-inner-icon="mdi-account-cog" small-chips></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" v-show="$store.getters.getGroupSelect.length > 1">
              <v-autocomplete :items="this.$store.getters.getGroupSelect" v-model="filterCamGroup" label="Grupo"
                hint="Selecione um ou mais grupos" persistent-hint clearable multiple chips small-chips
                prepend-inner-icon="mdi-group"></v-autocomplete>
            </v-col>
            <v-col class="pb-0">
              <v-autocomplete :items="this.$store.getters.getCamerasTypes" label="Modelo da Camera"
                v-model="filterCamModel" hint="Selecione um ou mais modelos" persistent-hint multiple chips small-chips
                deletable-chips clearable prepend-inner-icon="mdi-cctv"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete :items="['SIM', 'NÃO']" label="Usando Proxy"
                v-model="filterProxy" hint="Selecione para ser com ou sem proxy" persistent-hint clearable prepend-inner-icon="mdi-server-security"></v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <v-checkbox v-model="filterActive" label="Câmera ativa" hide-details></v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn color="success" text @click="exportExcell" class="mt-3">
                <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                Exportar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="clear" :disabled="!isFilterActive">Limpar filtros</v-btn>
          <v-btn color="primary" text @click="close">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cameras } from "@/Utils/Excel";
export default {
  name: "DialogFilter",
  props: ["value", "isFilterActive"],
  computed: {
    filterCamModel: {
      get() {
        return this.$store.getters.getFilterCamModel;
      },
      set(value) {
        this.$store.dispatch("setFilterCamModel", value);
      },
    },
    filterCamClient: {
      get() {
        return this.$store.getters.getFilterCamClient;
      },
      set(value) {
        this.$store.dispatch("setFilterCamClient", value);
      },
    },
    filterCamGroup: {
      get() {
        return this.$store.getters.getFilterCamGroup;
      },
      set(value) {
        this.$store.dispatch("setFilterCamGroup", value);
      },
    },
    filterText: {
      get() {
        return this.$store.getters.getFilterCam;
      },
      set(value) {
        this.$store.dispatch("setFilterCam", value || "");
      },
    },
    filterActive: {
      get() {
        return this.$store.getters.getFilterCamActive;
      },
      set(value) {
        this.$store.dispatch("setFilterCamActive", value || false);
      },
    },
    filterProxy: {
      get() {
        return this.$store.getters.getFilterCamProxy;
      },
      set(value) {
        console.log(value);
        this.$store.dispatch("setFilterCamProxy", value || '');
      },
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    clear() {
      this.filterCamModel = [];
      this.filterCamClient = [];
      this.filterCamGroup = [];
      this.filterText = "";
      this.filterActive = false;
      this.filterProxy = '';
    },
    exportExcell() {
      let c = this.$store.getters.getCamerasFilter;
      c.forEach((item) => {
        item.clientName = this.$store.getters.getClienteNameById(item.clientId);
        item.groupName = this.$store.getters.getGroupNameById(item.groupId);
        item.sts = this.$store.getters.getCameraStatusById(item.id);
      });
      cameras(c, "Relatorio de Cameras", "Cameras");
    },
  },
};
</script>

<style></style>