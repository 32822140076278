<template>
  <div>
    <v-dialog v-model="value" persistent>
      <v-card class="grey darken-3">
        <v-card-title> Desenhos {{ cameraName }} </v-card-title>
        <v-card-text>
          <!-- linha do select -->
          <v-row>
            <v-col>
              <v-select
                :items="types"
                label="Selecione o Tipo de Desenho"
                v-model="desenho.type"
                :hint="'Selecionado ' + getType(type)"
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- linha dos botões -->
          <v-row align="center" justify="center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!valid"
                  @click="save"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span>Salvar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="getImage">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Atualizar Imagem</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="clearPathCanvas">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Limpar</span>
            </v-tooltip>
          </v-row>
          <!-- linha do canvas e da tabela -->
          <v-row align="center" justify="center">
            <!-- Mostra a imagem quando carregada -->
            <canvas
              v-show="!loadingImage"
              id="desenho"
              width="380"
              height="380"
              @click="clickDraw"
              @contextmenu="undo"
              @mousemove="monitoringMouse"
            ></canvas>
            <!-- Mostra o spinner enquanto carrega -->
            <div
              v-show="loadingImage"
              class="minhaTabela"
              style="
                width: 380px;
                height: 380px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-progress-circular :size="300" color="primary" indeterminate>
                Carregando snapshot
              </v-progress-circular>
              <!-- <span>Carregando snapshot</span> -->
            </div>

            <v-simple-table dark height="380" class="mx-2 minhaTabela">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">ID</th>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Cadastrado</th>
                    <th class="text-left">
                      Ações
                      <v-btn icon color="success" @click="drawAll">
                        <v-icon>mdi-check-all</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="d in draws" :key="d.id">
                    <td>{{ d.id }}</td>
                    <td>{{ getType(d.type) }}</td>
                    <td>{{ new Date(d.createdAt).toLocaleString() }}</td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="showDraw(d)"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" @click="del(d)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Excluir</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <small v-show="resolucao.width">
            Resolução da Câmera: {{ this.resolucao.width }}x{{
              this.resolucao.height
            }}
          </small>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogDraw",
  props: ["value", "cameraId", "cameraName", "camera"],
  data: () => ({
    points: [],
    canvas: null,
    context: null,
    finish: false,
    types: [
      // { value: 1, text: "Linha" },
      { value: 2, text: "Poligono" },
    ],
    type: 2,
    img: null,
    valid: false,
    desenho: {
      id: 0,
      cameraId: 0,
      type: 2,
      payload: [],
    },
    draws: [],
    sizes: {
      camWidth: 0,
      camHeight: 0,
      canvasWidth: 0,
      canvasHeight: 0,
    },
    loadingImage: false,
    resolucao: {},
  }),
  methods: {
    close() {
      this.points = [];
      this.type = "";
      this.$emit("input", false);
      // limpa o canvas
      let canvas = document.getElementById("desenho");
      let context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    },
    clickDraw(value) {
      if (this.finish) {
        this.points.push([this.points[0][0], this.points[0][1]]);
        this.draw(true);
      } else {
        this.points.push([value.offsetX, value.offsetY]);
        this.draw();
      }
    },
    undo(e) {
      e.preventDefault();
      this.points.pop();
      this.draw();
      this.finish = false;
    },
    draw(changeColor) {
      let canvas = document.getElementById("desenho");
      let context = canvas.getContext("2d");
      if (this.img) {
        let image = new Image();
        image.onload = () => {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          context.beginPath();
          this.points.forEach((e, i) => {
            // largura da linha
            context.lineWidth = 3;
            if (changeColor) {
              context.strokeStyle = "rgba(0, 255, 0, 1)";
            } else {
              context.strokeStyle = "rgba(255, 255, 0, 1)";
            }
            if (!i) {
              if (this.finish) {
                context.moveTo(e[0], e[1]);
              } else {
                context.arc(e[0], e[1], 5, 0, 2 * Math.PI);
                context.moveTo(e[0], e[1]);
              }
            } else {
              context.lineTo(e[0], e[1]);
            }
          });
          context.stroke();
          context.closePath();
        };
        image.src = this.img;
      }
    },
    drawAll() {
      // desenha todas
      let canvas = document.getElementById("desenho");
      let context = canvas.getContext("2d");
      let self = this;
      if (this.img) {
        let image = new Image();
        image.onload = () => {
          self.resolucao = {
            width: image.width,
            height: image.height,
          };
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          this.draws.forEach((points) => {
            let ppoints = this.getProportionalSize(points.payload, true);
            context.beginPath();
            ppoints.forEach((e, i) => {
              // largura da linha
              context.lineWidth = 3;
              // cor da linha
              context.strokeStyle = "rgba(0, 255, 0, 1)";
              if (!i) {
                if (this.finish) {
                  context.moveTo(e[0], e[1]);
                } else {
                  context.arc(e[0], e[1], 5, 0, 2 * Math.PI);
                  context.moveTo(e[0], e[1]);
                }
              } else {
                context.lineTo(e[0], e[1]);
              }
            });
            context.stroke();
            context.closePath();
          });
        };
        image.src = this.img;
      }
    },
    monitoringMouse(value) {
      if (this.finish) return;
      // validação do poligono
      if (this.points.length > 1 && this.desenho.type == 2) {
        let x = this.points[0][0];
        let y = this.points[0][1];
        if (
          x + 5 >= value.offsetX &&
          x - 5 <= value.offsetX &&
          y + 5 >= value.offsetY &&
          y - 5 <= value.offsetY
        ) {
          this.draw(true);
          this.valid = true;
          this.finish = true;
        } else {
          if (this.finish) {
            this.finish = false;
            this.draw();
          }
        }
      }
      // validação da linha
      if (this.desenho.type == 1) {
        if (this.points.length == 2) {
          // console.log("if da linha");
          this.draw(true);
          this.valid = true;
          this.finish = true;
        } else {
          // console.log("else da linha");
          if (this.finish) {
            this.finish = false;
            this.draw();
          }
        }
      }
    },
    getImage() {
      let id = this.cameraId;
      if (!id) {
        id = this.camera.id;
      }
      // console.log("snapshot?id=" + id);
      // busca a imagem do servidor
      this.resolucao = {};
      if (this.cameraId) {
        this.loadingImage = true;
        this.$http
          .post("/snapshot", { id: this.cameraId }, {
          // .get("snapshot?id=" + id, {
            responseType: "arraybuffer",
          })
          .then((img) => {
            let canvas = document.getElementById("desenho");
            let context = canvas.getContext("2d");
            let image = new Image();
            image.onload = () => {
              this.sizes.camWidth = image.width;
              this.sizes.camHeight = image.height;
              this.sizes.canvasWidth = canvas.width;
              this.sizes.canvasHeight = canvas.height;
              context.drawImage(image, 0, 0, canvas.width, canvas.height);
            };
            this.img =
              "data:" +
              img.headers["content-type"] +
              ";base64," +
              Buffer.from(img.data).toString("base64");
            image.src = this.img;
            this.loadingImage = false;
            setTimeout(() => {
              this.drawAll();
            }, 1000);
            return this.img;
          })
          .catch(() => {
            // console.log(err);
            this.loadingImage = false;
          });
      }
    },
    clearPathCanvas() {
      // limpa os desenhos canvas e carrega a imagem
      this.points = [];
      let canvas = document.getElementById("desenho");
      let context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      if (this.img) {
        let image = new Image();
        image.onload = () => {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          context.beginPath();
          context.closePath();
        };
        image.src = this.img;
      }
      this.finish = false;
    },
    clearCanvas() {
      // limpa o canvas
      let canvas = document.getElementById("desenho");
      let context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.valid = false;
      this.finish = false;
      this.points = [];
    },
    save() {
      if (this.valid) {
        this.desenho.payload = this.getProportionalSize(this.points);
        this.desenho.cameraId = this.cameraId;
        this.$http.post("/desenhoCameras", this.desenho).then(() => {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Desenho salvo com sucesso");
          this.getDraws();
        });
      }
    },
    getDraws() {
      this.$http.get("/desenhoCameras/" + this.cameraId).then((res) => {
        this.draws = res.data;
      });
    },
    getProportionalSize(points, reverse) {
      let ret = [];
      let cx = this.camera.config.detectionLimits.width;
      let cy = this.camera.config.detectionLimits.height;
      let x = cx / this.sizes.canvasWidth;
      let y = cy / this.sizes.canvasHeight;
      if (reverse) {
        points.forEach((p) => {
          ret.push([parseInt(p[0] / x), parseInt(p[1] / y)]);
        });
      } else {
        points.forEach((p) => {
          ret.push([parseInt(p[0] * x), parseInt(p[1] * y)]);
        });
      }
      return ret;
    },
    getType(id) {
      let t = this.types.find((e) => e.value == id);
      if (t) {
        return t.text;
      } else {
        return "Desconhecido";
      }
    },
    del(item) {
      this.$http.delete("/desenhoCameras/" + item.id).then(() => {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showSuccessSnack",
          "Desenho Deletado com Sucesso"
        );
        this.getDraws();
      });
    },
    showDraw(item) {
      this.clearCanvas();
      this.type = item.type;
      this.points = item.payload;
      this.draw();
    },
  },
  watch: {
    value() {
      if (this.value) {
        // console.log("snapshot/" + this.cameraId);
        this.getImage();
        this.getDraws();
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
#desenho {
  cursor: crosshair;
}
canvas {
  border: 2px solid;
  border-collapse: collapse;
}
.minhaTabela {
  border: 2px solid;
  border-collapse: collapse;
}
</style>