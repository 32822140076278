<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          label="Endereço para acesso da câmera ip ou ddns"
          v-model="camera.url"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field label="Usuário" v-model="camera.user"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field label="Senha" v-model="camera.password"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Numero da Zona"
          v-model.number="camera.zone"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Porta HTTP da câmera ou NVR / DVR"
          v-model="camera.config.httpPort"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Porta RTSP da câmera ou NVR / DVR"
          v-model="camera.config.rtspPort"
          type="number"
          :disabled="camera.modelId == 6"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Canal da câmera ou NVR / DVR"
          v-model="camera.config.channel"
          type="number"
          min="1"
          max="32"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Type5Options",
  props: ["camera"],
  data: () => ({
    rtspPort: 554,
    httpPort: 80,
    channel: 1,
  }),
  methods: {},
};
</script>

<style>
</style>