<template>
  <v-dialog v-model="dialog" :max-width="width">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <div v-html="text"></div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel"> Cancelar </v-btn>
        <v-btn color="success" @click="confirm">
          {{ confirmBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data: () => ({
    dialog: false,
    title: "Confirmar",
    text: "Tem certeza que deseja confirmar?",
    confirmBtn: "Confirmar",
    width: "500px",
    confirmCallback: null,
  }),
  methods: {
    open(options) {
      if (options.title) this.title = options.title;
      if (options.text) this.text = options.text;
      if (options.confirmBtn) this.confirmBtn = options.confirmBtn;
      if (options.width) this.width = options.width;
      if (options.confirmCallback)
        this.confirmCallback = options.confirmCallback;
      this.dialog = true;
    },
    cancel() {
      //this.$emit("cancel");
      this.dialog = false;
    },
    confirm() {
      if (typeof this.confirmCallback == "function") this.confirmCallback();
      //this.$emit("confirm");
      this.dialog = false;
    },
  },
  created() {
    this.title;
  },
};
</script>

<style>
</style>