<template>
  <div>
    <v-dialog v-model="value" persistent scrollable>
      <v-card>
        <v-card-title>
          Ações para câmera: {{ camera.id }} - {{ camera.name }}

          <v-spacer></v-spacer>
          <v-btn color="success" @click="dialoagFromAction = true"
            ><v-icon class="mr-2">mdi-plus</v-icon> Adicionar ação
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table dark v-if="getCameraActions.length">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Icone</th>
                  <th class="text-left">Nome</th>
                  <th class="text-left">Descrição</th>
                  <th class="text-left">Ativo</th>
                  <th class="text-left">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getCameraActions" :key="item.name">
                  <td>
                    <v-icon>{{ item.icon }}</v-icon>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>
                    <div v-for="(w, idx) in item.do" :key="idx + 'a'">
                      <span class="smallText font-weight-black"
                        >Dispositivo:
                      </span>
                      <span class="smallText font-weight-medium mx-2">{{
                        w.device.name
                      }}</span>
                      <span class="smallText font-weight-black">Ação:</span>
                      <span class="smallText font-weight-medium mx-2"
                        >{{ w.action.name }}
                      </span>
                      <span
                        class="smallText font-weight-black"
                        v-if="w.delay > 0"
                        >Atrazo:</span
                      >
                      <span
                        class="smallText font-weight-medium ml-1"
                        v-if="w.delay > 0"
                        >{{ w.delay / 1000 }} segs
                      </span>
                    </div>
                  </td>
                  <td>{{ item.enabled ? "SIM" : "NÃO" }}</td>
                  <td>
                    <!-- botão editar -->
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="edit(item)"
                        >
                          <v-icon color="warning" class="mr-1"
                            >mdi-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Editar : {{ item.name }}</span>
                    </v-tooltip>
                    <!-- botão excluir -->
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="deletAutomation(item)"
                        >
                          <v-icon color="red">mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                      <span>Excluir : {{ item.name }}</span>
                    </v-tooltip>
                    <!-- botão executar -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="runAction(item)"
                        >
                          <v-icon color="success">mdi-play</v-icon>
                        </v-btn>
                      </template>
                      <span>Executar : {{ item.name }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else>
            <v-alert type="info" elevation="2" outlined>
              Nenhuma ação cadastrada
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirmDialog" />
    <DialogFromAction
      v-model="dialoagFromAction"
      :devices="devices"
      @save="addAction"
      :act="action"
    />
  </div>
</template>

<script>
import DialogFromAction from "./partials/DialogFormAction.vue";
import ConfirmDialog from "../../../components/ConfirmDialog.vue";
import { mapGetters } from "vuex";
export default {
  name: "DialogAction",
  props: ["value", "camera"],
  components: {
    DialogFromAction,
    ConfirmDialog,
  },
  data: () => ({
    action: {},
    actions: [],
    devices: [],
    dialoagFromAction: false,
  }),
  methods: {
    close() {
      this.$emit("input", false);
    },
    addAction(action) {
      this.action = {};
      action.cameraId = this.camera.id;
      this.$store.dispatch("saveCameraAction", action);
    },
    deletAutomation(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Ação",
        text: `Deseja realmente excluir a ação <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("deletCameraAction", item);
        },
      });
    },
    edit(item) {
      this.action = item;
      this.dialoagFromAction = true;
    },
    getActions() {
      this.$store.dispatch("loadCameraActions", this.camera);
    },
    runAction(data) {
      this.$store.dispatch("runAction", data);
    },
  },
  computed: {
    ...mapGetters(["getDevices", "getCameraActions"]),
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getActions();
      }
    },
  },
};
</script>

<style scoped>
</style>